import React, {useEffect} from "react";
import bgImage from "./../../../images/reset-pawword.jpg";
import BackNavButton from "../../../components/UIs/backHome/BackNavButton";
import {useFormik} from "formik";
import * as Yup from "yup";
import PasswordInput from "../../../components/UIs/passwordInput/PasswordInput";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../redux/hook";
import {
  passwordTokenVerifyThunk
} from "../../../redux/features/password-token-verification/thunk/passwordTokenVerify.thunk";
import {RootState} from "../../../redux/store";
import Swal from "sweetalert2";
import {resetPassword} from "../../../redux/features/reset-password/thunk/resetPassword.thunk";
import SubmitBtnWithLoader from "../../../components/UIs/submitBtnWithLoader/SubmitBtnWithLoader";

const PasswordReset: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('hash');
  const dispatch = useAppDispatch();
  const verificationStatus = useAppSelector(
      (state: RootState) => state.passwordTokenVerification.status
  );
  const tokenStatus = useAppSelector(
      (state: RootState) => state.passwordTokenVerification.tokenStatus
  );
  const resetPasswordStatus = useAppSelector(
      (state: RootState) => state.resetPassword.status
  )

  useEffect(() => {
    if (token != null) {
      dispatch(passwordTokenVerifyThunk(token));
    }
  }, [token, dispatch]);

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Ce champ est obligatoire")
        .min(8, "Le mot de passe doit contenir au moins 8 caractères"),
      password_confirmation: Yup.string()
        .required("Ce champ est obligatoire")
        .min(8, "Le mot de passe doit contenir au moins 8 caractères")
        .oneOf(
          [Yup.ref("password"), ""],
          "Les mots de passe doivent correspondre"
        ),
    }),
    onSubmit: payload => {
      if (token) {
        const newPayload = {...payload, account: token};
        dispatch(resetPassword(newPayload));
      }
    },
  });

  useEffect(() => {
    if (verificationStatus === "failed") {
      Swal.fire({
        title: "Erreur",
        iconColor: "#d33",
        text: "Il semble que le lien que vous avez utilisé n'est plus valide, veuillez reprendre le processus de réinitialisation du mot de passe.",
        icon: "error",
        confirmButtonColor: "#FF7F50",
      });
    } else if (verificationStatus === "succeeded") {
      Swal.fire({
        title: "Réinitialisation du mot de passe",
        iconColor: "#34d399",
        text: "Veuillez suivre ses instructions du formulaire pour terminer le processus de réinitialisation du mot de passe.",
        icon: "success",
        confirmButtonColor: "#FF7F50",
      });
    }
  }, [verificationStatus, tokenStatus]);

  useEffect(() => {
    if (resetPasswordStatus === "succeeded") {
      Swal.fire({
        title: "Réinitialisation du mot de passe",
        iconColor: "#34d399",
        text: "Votre mot de passe a bien été changé. Veuillez vous connecter avec votre nouveau mot de passe.",
        icon: "success",
        confirmButtonColor: "#FF7F50",
      });
      setTimeout(() => {
        navigate("/auth/login");
      }, 2000);
    }
  }, [resetPasswordStatus]);

  return (
    <>
      <div
        className="w-full md:w-2/3 bg-cover"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
        }}></div>
      <div className="w-full flex-col flex justify-center md:w-1/3 p-4 md:p-12 overflow-y-auto max-h-screen">
        <BackNavButton/>
        <h1 className="font-bold text-4xl mb-2">
          Réinitialisez votre mot de passe
        </h1>
        {verificationStatus === "failed" ? (
            <div className="text-red-500 my-6 text-center">
              Il semble que le lien que vous avez utilisé n&apos;est plus valide,
              veuillez reprendre le processus de réinitialisation du mot de passe svp.
              <div className="flex justify-center mt-6">
                <svg width="100" height="100" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="10" fill="#f8d7da" strokeWidth="2"/>
                  <path d="M8 8l8 8m0-8l-8 8" stroke="#dc3545" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </div>
            </div>
        ) : (
            <>
              <p className="small mb-6">Veuillez remplir le formulaire ci-dessous</p>
              <form className="w-full" onSubmit={formik.handleSubmit}>
                <PasswordInput
                    label="Nouveau mot de passe"
                    id={`password`}
                    name={`password`}
                    placeholder="Nouveau mot de passe"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.password}
                    error={formik.errors.password}
                />

                <PasswordInput
                    label="Confirmation du mot de passe"
                    id={`password_confirmation`}
                    name={`password_confirmation`}
                    placeholder="Confirmez le mot de passe"
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.password_confirmation}
                    error={formik.errors.password_confirmation}
                />
                <SubmitBtnWithLoader
                    label="Réinitialiser mon mot de passe"
                    status={resetPasswordStatus}
                    isDisabled={!formik.isValid}
                />
              </form>
            </>
        )}
      </div>
    </>
  );
}

export default PasswordReset;
