import {createAsyncThunk} from "@reduxjs/toolkit";
import {axios} from "../../../../lib/axios";
import {GetCategoriesByCategoryCommand} from "../command/GetCagnottesByCategoriesCommand copy";

export const getCagnottesByCategories = createAsyncThunk(
  "fetchByCategory/cagnottes",
  async (payload: GetCategoriesByCategoryCommand) => {
    const response = await axios.get(
      `jackpots/99b93824-8625-4546-98eb-97eebc37b172/${payload.categoryId}/all?page=${payload.currentPage}`
    );

    return response.data;
  }
);
