import {createSlice} from "@reduxjs/toolkit";
import {InitializePaymentState} from "./state/InitializePaymentState.state";
import {initializePayment} from "./thunk/initializePayment.thunk";

const initialState : InitializePaymentState = {
    data: {
        amount: 0,
        id: "",
        devise: "",
    },
    initializePaymentStatus: "idle",
    error: null,
}

export const payCagnotteSlice = createSlice({
    name: "pay",
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(initializePayment.pending, state => {
                state.initializePaymentStatus = "loading";
            })
            .addCase(initializePayment.fulfilled, (state, action) => {
                state.initializePaymentStatus = "succeeded";
                state.data = action.payload;
            })
            .addCase(initializePayment.rejected, (state, action) => {
                state.error = action.error.message;
                state.initializePaymentStatus = "failed";
            });
    },
});

export default payCagnotteSlice.reducer;