import howItWorks from "./../../images/how-it-works.jpeg";
import React from "react";
import CustomLink from "../UIs/customLink/CustomLink";

function TutoStep() {
  return (
    <section className="py-10">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center mt-10">
        <div className="w-full lg:w-1/2 h-[600px] p-6 md:p-0">
          <img
            src={howItWorks}
            alt="Un jeune homme montrant des informations dans une tablette à sa voisine"
            className="w-full h-full object-cover rounded"
          />
        </div>

        <div className="w-full lg:w-1/2 p-10">
          <h2 className="text-3xl font-bold tracking-tight text-corail sm:text-4xl mb-7">
            Créez votre cagnotte en quelques étapes simples avec YoCagnotte
          </h2>
          <div className="w-full">
            <div className="shadow rounded-xl flex justify-start p-2 mb-3">
              <button
                type="button"
                className="flex justify-center items-center w-[52px] h-[52px] text-white bg-corail-100 rounded-full">
                1
              </button>
              <div className="ms-3 text-1xl font-semibold self-center">
                Créez votre compte YoCagnotte
              </div>
            </div>
            <div className="shadow rounded-xl flex justify-start p-2 mb-3">
              <button
                type="button"
                className="flex justify-center items-center w-[52px] h-[52px] text-white bg-corail-100 rounded-full">
                2
              </button>
              <div className="ms-3 text-1xl font-semibold self-center">
                Remplissez le formulaire de création de cagnotte
              </div>
            </div>
            <div className="shadow rounded-xl flex justify-start p-2 mb-3">
              <button
                type="button"
                className="flex justify-center items-center w-[52px] h-[52px] text-white bg-corail-100 rounded-full">
                3
              </button>
              <div className="ms-3 text-1xl font-semibold self-center">
                Partagez le lien à vos proches
              </div>
            </div>
          </div>
          <div className="mt-10 flex items-center justify-center lg:justify-start">
            <CustomLink customClass="bg-corail text-white text-corail font-bold rounded py-2 px-4 font-medium">
              Créer une cagnotte
            </CustomLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TutoStep;
