import {useEffect} from "react";
import CustomLink from "../../../components/UIs/customLink/CustomLink";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../redux/hook";
import {RootState} from "../../../redux/store";
import {fetchCagnotteDetails} from "../../../redux/features/cagnottes/thunk/fetchCagnotteDetails.thunk";
import {FaCalendarAlt} from "react-icons/fa";
import {IoMdClose, IoMdOpen} from "react-icons/io";
import {MdEdit, MdGroups2, MdSchedule} from "react-icons/md";
import {useFormik} from "formik";
import * as Yup from "yup";
import {getAllCategories} from "../../../redux/features/categories/thunk/getAllCategoriesThunk";
import formatAmount, {
  calculatePercentage,
  calculateRemainingDays,
  convertImageToBase64,
  formatDate,
  formatDateDayMounthYear
} from "../../../helpers/functionsHelper";
import CagnotteLink from "../../../components/UIs/cagnotteLink/CagnotteLink";
import BackNavButton from "../../../components/UIs/backHome/BackNavButton";
import Participants from "../../../components/UIs/participants/Participants";
import {closeCagnotte} from "../../../redux/features/cagnottes/thunk/closeCagnotte.thunk";
import {openCagnotte} from "../../../redux/features/cagnottes/thunk/openCagnotte.thunk";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import ShareButtons from "../../../components/UIs/share-buttons/ShareButton";
import {ThreeDots} from "react-loader-spinner";
import HeroHeader from "../../../components/heroHeader/HeroHeader";
// import cover from "../../../images/detail-cover.jpg";
import Search from "../../../components/UIs/search/Search";
import Modal from "../../../components/modal/Modal";
import {openModal} from "../../../redux/features/modal/ModalSlice";
import {Category} from "../../../models/Category";
import FileUploadBtn from "../../../components/UIs/fileUploadBtn/FileUploadBtn";
import Switch from "../../../components/UIs/switch/switch/switch";
import SubmitBtnWithLoader from "../../../components/UIs/submitBtnWithLoader/SubmitBtnWithLoader";
import {updateCagnotteThunk} from "../../../redux/features/cagnottes/thunk/updateCagnotte.thunk";
import {UpdateCagnotteCommand} from "../../../redux/features/cagnottes/command/UpdateCagnotteCommand";

const DetailsCagnotte = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const shareUrl = window.location.href;
  const cagnotteDetails = useAppSelector(
    (state: RootState) => state.cagnotteDetails.details
  );
  const updateCagnotteStatus = useAppSelector((state: RootState) => state.updateCagnotte.status);

  const isOwner = useAppSelector(
    (state: RootState) => state.cagnotteDetails.owner
  );
  const detailsCagnotteStatus = useAppSelector(
    (state: RootState) => state.cagnotteDetails.status
  );

  const cash_in = useAppSelector(
    (state: RootState) => state.cagnotteDetails.cash_in
  );
  const currency = useAppSelector(
    (state: RootState) => state.cagnotteDetails.currency
  );
  const amount_paid = useAppSelector(
    (state: RootState) => state.cagnotteDetails.amount_paid
  );

  const handleCloseCagnotte = async (cagnotteId: string) => {
    Swal.fire({
      title: "Fermer une cagnotte",
      text: "Êtes vous sûr de vouloir effectuer cette action ?",
      icon: "warning",
      iconColor: "#FF7F50",
      showCancelButton: true,
      confirmButtonColor: "#FF7F50",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, fermer",
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(closeCagnotte({jackpot: cagnotteId}));
        Swal.fire({
          title: "Cagnotte fermée",
          iconColor: "#34d399",
          text: "La cagnotte est fermée avec succès",
          icon: "success",
          confirmButtonColor: "#FF7F50",
        });
        toast.success("Votre cagnotte est à présent fermée.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '14px'
          }
        });
      }
    });
  };

  const handleOpenCagnotte = async (cagnotteId: string) => {
    Swal.fire({
      title: "Ouvrir une cagnotte",
      text: "Êtes vous sûr de vouloir effectuer cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF7F50",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, ouvrir",
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(
          openCagnotte({
            jackpot: cagnotteId,
            expired_at: cagnotteDetails?.expired_at.split(" ")[0],
          })
        );
        Swal.fire({
          title: "Cagnotte ouverte",
          iconColor: "#34d399",
          text: "La cagnotte est à présent ouverte.",
          icon: "success",
          confirmButtonColor: "#FF7F50",
        });
        toast.success("Votre cagnotte est à présent ouverte.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };

  useEffect(() => {
    if (params.id) {
      dispatch(fetchCagnotteDetails({id: params.id}));
    }
    dispatch(getAllCategories());
  }, [params]);

  const formik = useFormik({
    initialValues: {
      title: cagnotteDetails?.title,
      expired_at: cagnotteDetails?.expired_at,
      type_id: cagnotteDetails?.type_id,
      description: cagnotteDetails?.description,
      statusPrivate: cagnotteDetails?.private,
      unitAmount: cagnotteDetails?.unit_amount,
      totalAmount: cagnotteDetails?.total_amount,
      image: Yup.mixed()
        .test("fileSize", "Fichier trop volumineux", value => {
          if (!value) return false;
          return (value as File).size <= 2 * 1024 * 1024;
        })
        .required("Vous devez ajouter une image"),
    },
    onSubmit: async (values) => {
      const payload: UpdateCagnotteCommand = {
        jackpot: cagnotteDetails?.id,
        type: values.type_id,
        title: values.title,
        description: values.description,
        expired_at: values.expired_at,
        unit_amount: Number(values.unitAmount),
        total_amount: Number(values.totalAmount),
        private: values.statusPrivate,
      };

      if (values.image !== null && typeof values.image === 'object' && values.image instanceof File) {
        payload.image = await convertImageToBase64(values.image);
      }

      Swal.fire({
        title: "Modification de votre cagnotte",
        text: "Êtes vous sûr de vouloir effectuer cette action ?",
        icon: "warning",
        iconColor: "#FF7F50",
        showCancelButton: true,
        confirmButtonColor: "#FF7F50",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui",
      }).then(result => {
        if (result.isConfirmed) {
          dispatch(updateCagnotteThunk(payload));
        }
      });
    },
  });

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      title: cagnotteDetails?.title || "",
      expired_at: cagnotteDetails?.expired_at || "",
      type_id: cagnotteDetails?.type_id || "",
      description: cagnotteDetails?.description || "",
      statusPrivate: cagnotteDetails?.private || false,
      unitAmount: cagnotteDetails?.unit_amount,
      totalAmount: cagnotteDetails?.total_amount,
    });
  }, [cagnotteDetails]);

  const categories = useAppSelector(
      (state: RootState) => state.categories.categories
  );

  const handleOpenModal = () => {
    dispatch(openModal());
  }

  useEffect(() => {
    if (updateCagnotteStatus === "succeeded") {
      Swal.fire({
        title: "Cagnotte modifiée",
        iconColor: "#34d399",
        text: "La cagnotte a été modifié avec succès",
        icon: "success",
        confirmButtonColor: "#FF7F50",
      });
      toast.success("Votre cagnotte a été modifié avec succès.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
          window.location.reload();
      }, 3000);
    }
  }, [updateCagnotteStatus]);

  return (
    <>
      <div className="mt-16">
        <HeroHeader
            img={cagnotteDetails?.image}
            greatTitle={cagnotteDetails?.title}
            isLoading={detailsCagnotteStatus === "loading"}
        />
        <Search pageResult="/cagnotte/cagnottes" />
      </div>
      <div className="max-w-7xl mx-auto">
        <BackNavButton />
      </div>
      <div className="h-full max-w-7xl mx-auto flex flex-col md:flex-row">
        <section id="left-side" className="w-full md:w-2/3">
          <div className="px-4 sm:px-6 lg:px-1">
            <div className="w-full mb-4 md:mb-0 rounded-lg">
              {detailsCagnotteStatus === "loading" ? (
                <div className="flex align-center justify-center">
                  <ThreeDots
                    visible={true}
                    height="24.5"
                    color="#ff7f50"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <img
                  src={cagnotteDetails?.image}
                  alt="Couverture de la cagnotte"
                  className="object-cover rounded-lg w-full md:h-[500px]"
                />
              )}
            </div>
          </div>
          <div className="px-4 sm:px-6 lg:px-1 mt-10">
            <div className="w-full">
              <h2 className="text-2xl font-semibold">Partager la cagnotte</h2>
              <CagnotteLink />
              <div className="display-description mt-10">
                <div className="mt-4 flex items-center gap-3">
                  <h2 className="text-2xl font-semibold">Description</h2>
                </div>
                <div className="mt-4" style={{ whiteSpace: "pre-wrap" }}>{cagnotteDetails?.description}</div>

                <div className="my-4 flex items-center justify-start flex-col gap-2 md:flex-row">
                  <div className="w-full py-2 px-4 border rounded-lg gap-2 flex items-center justify-center gap-3 text-gray-500 font-semibold">
                    Catégorie : {cagnotteDetails?.type_name}
                  </div>
                  <div className="w-full py-2 px-4 border rounded-lg gap-2 flex items-center justify-center text-gray-500 font-semibold">
                    <FaCalendarAlt />
                    {cagnotteDetails?.expired_at
                        ? "Expiration : " +
                        formatDateDayMounthYear(cagnotteDetails.expired_at)
                        : "Pas de date d'expiration"}
                  </div>
                </div>
              </div>
            </div>
            {cagnotteDetails && (
                <Participants
                    cagnotteId={cagnotteDetails.id}
                    country={`99b93824-8625-4546-98eb-97eebc37b172`}
                />
            )}
          </div>
        </section>
        <div className="w-full h-full md:w-1/3 flex flex-col justify-start px-6 md:px-4">
          <div>
            <h2 className="font-semibold text-3xl mb-5 flex gap-4 items-start">
              {cagnotteDetails?.title}
              {
                isOwner ?
                    <button onClick={() => handleOpenModal()}>
                      <MdEdit
                          className="text-corail-100 text-2xl cursor-pointer"
                          title="Modifier la cagnotte"
                      />
                    </button> : null
              }
            </h2>
            <div className="mb-5">
              <p className="text-gray-400">Montant total récolté</p>
              <span className="font-semibold text-5xl">
                {formatAmount(amount_paid)}
              </span>{" "}
              {currency}
            </div>
            <div className="text-sm">
              La cagnotte a été créé par{" "}
              <span className="font-semibold">
                {cagnotteDetails?.firstname} {cagnotteDetails?.lastname}
              </span>
            </div>
          </div>
          <hr className="h-px my-4 bg-gray-200" />
          <div className="flex gap-4 justify-center items-center text-gray-400">
            {cash_in <= 0 ? (
              <span className="flex items-center gap-4">
                <MdGroups2 className="text-2xl" /> Aucun participant pour
                l&apos;instant
              </span>
            ) : (
              <span className="flex items-center gap-4">
                <MdGroups2 className="text-2xl" /> {formatAmount(cash_in)} {" "}
                participant(s)
              </span>
            )}
          </div>
          <hr className="h-px my-4 bg-gray-200" />
          <div className="flex justify-between p-4 text-xs border border-gray-200 rounded">
            <span className="text-gray-400">
              Créée le {" "}
              {cagnotteDetails?.created_at &&
                new Date(cagnotteDetails?.created_at).toLocaleDateString(
                  "fr-FR"
                )}
            </span>
            {cagnotteDetails?.expired_at !== null &&
            cagnotteDetails?.expired_at !== undefined ? (
              new Date(cagnotteDetails?.expired_at) > new Date() ? (
                <span className="text-gray-400 flex items-center gap-1">
                  <MdSchedule className="text-md" />{" "}
                  {calculateRemainingDays(cagnotteDetails?.expired_at)} jour(s)
                  restant
                </span>
              ) : (
                <span className="text-red-500 flex items-center gap-2">
                  <MdSchedule className="text-lg" /> Expiré
                </span>
              )
            ) : null}
          </div>
          {cagnotteDetails?.total_amount ? (
            <>
              <hr className="h-px my-4 bg-gray-200" />
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-corail-500 h-2.5 rounded-full"
                  style={{
                    width: cagnotteDetails?.total_amount
                      ? calculatePercentage(Number(amount_paid), Number(cagnotteDetails.total_amount)) + "%"
                      : 0 + "%",
                  }}></div>
              </div>
              <div className="text-sm flex justify-between">
                <div>
                  {formatAmount(amount_paid)} {currency}{" "}
                  <span className="text-gray-400">récolté</span>
                </div>
                <div>
                  <span className="text-gray-400">sur</span>{" "}
                  {formatAmount(cagnotteDetails?.total_amount)} {currency}
                </div>
              </div>
            </>
          ) : null}
          <hr className="h-px my-4 bg-gray-200 border-0" />
          <div>
            {cagnotteDetails?.expired_at &&
            new Date(cagnotteDetails?.expired_at) < new Date() ? (
              <button
                type="button"
                className="block text-center w-full p-4 rounded-lg font-semibold mb-3 cursor-not-allowed text-gray-400 bg-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-100">
                Participer
              </button>
            ) : (
              <CustomLink
                link={"/cagnotte/" + cagnotteDetails?.id + "/participate"}
                customClass={`block text-center w-full p-4 rounded-lg font-semibold mb-3 bg-corail-100 hover:bg-corail-500 text-white`}>
                Participer
              </CustomLink>
            )}
            <ShareButtons shareUrl={shareUrl} />
            {isOwner && cagnotteDetails?.id && (
              <>
                {cagnotteDetails.active ? (
                  <button
                    className="flex align-center justify-center w-full border-[#dc2626] border-2 text-corail text-[#dc2626] p-4 rounded-lg font-semibold mb-3"
                    onClick={() => handleCloseCagnotte(cagnotteDetails.id)}>
                    <IoMdClose className="text-2xl me-3" /> Fermer la cagnotte
                  </button>
                ) : (
                  <button
                    className="flex align-center justify-center w-full border-[#34d399] border-2 text-green-500 p-4 rounded-lg font-semibold my-3"
                    onClick={() => handleOpenCagnotte(cagnotteDetails.id)}>
                    <IoMdOpen className="text-2xl me-3" /> Ouvrir la cagnotte
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Modal modalTitle="Modification d'une Cagnotte">
        <h4>
          Modification de la cagnotte <strong className="text-corail-100">{cagnotteDetails?.title}</strong>
        </h4>
        <div className="mt-6">
          <form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="flex flex-col md:flex-row items-center justify-between gap-3">
                <div className="mb-6 w-full md:w-1/2">
                  <label
                      htmlFor="title"
                      className="block mb-2 text-sm font-medium text-gray-900">
                    Nom de la cagnotte
                  </label>
                  <input
                      type="text"
                      id="title"
                      name="title"
                      className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:outline-none focus:ring-2 focus:ring-corail-500 block w-full p-2.5`}
                      placeholder="Anniversaire de..."
                      required
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  />
                  {formik.touched.title && formik.errors.title && (
                      <div className="text-sm text-red-500">
                        {formik.errors.title}
                      </div>
                  )}
                </div>
                <div className="mb-6 w-full md:w-1/2">
                  <label
                      htmlFor="type_id"
                      className="block mb-2 text-sm font-medium text-gray-900">
                    Catégorie
                  </label>
                  <select
                      name="type_id"
                      id="type_id"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                      value={formik.values.type_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}>
                    <option value="#">Choisir une catégorie</option>
                    {
                      categories.map((category: Category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                      ))
                    }
                  </select>
                  {formik.touched.type_id && formik.errors.type_id && (
                      <div className="text-sm text-red-500">{formik.errors.type_id}</div>
                  )}
                </div>
              </div>

              <div className="mb-6">
                <label
                    htmlFor="expired_at"
                    className="block mb-2 text-sm font-medium text-gray-900">
                  Date d&apos;expiration (facultatif)
                </label>
                <input
                    type="date"
                    id="expired_at"
                    name="expired_at"
                    // min={new Date().toISOString().split("T")[0]}
                    min={
                      new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                          .toISOString()
                          .split("T")[0]
                    }
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                    value={formatDate(formik.values.expired_at)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.expired_at && formik.errors.expired_at && (
                    <div className="text-sm text-red-500">
                      {formik.errors.expired_at}
                    </div>
                )}
              </div>
              <div className="mb-6">
                <FileUploadBtn
                    label="Choisir un fichier"
                    field={formik.getFieldProps("image")}
                    form={formik}
                />
                {/*{formik.touched.image && formik.errors.image && (*/}
                {/*    <div className="text-sm text-red-500">*/}
                {/*      {formik.errors.image}*/}
                {/*    </div>*/}
                {/*)}*/}
              </div>
              <div className="mb-6">
                <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900">
                  Description
                </label>
                <textarea
                    name="description"
                    id="description"
                    cols={30}
                    rows={5}
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}></textarea>
                {formik.touched.description && formik.errors.description && (
                    <div className="text-sm text-red-500">
                      {formik.errors.description}
                    </div>
                )}
              </div>
              <Switch
                  title="Rendre la cagnotte privée"
                  description="Votre cagnotte ne sera pas accessible à tous les utilisateurs"
                  name="statusPrivate"
                  field={formik.getFieldProps("statusPrivate")}
                  form={formik}
              />
              <div className="flex flex-col md:flex-row items-center justify-between gap-3">
                <div className="mb-4 w-full md:w-1/2">
                  <label
                      htmlFor="unitAmount"
                      className="block mb-2 text-sm font-medium text-gray-900">
                    Montant minimum (facultatif)
                  </label>
                  <input
                      type="number"
                      id="unitAmount"
                      name="unitAmount"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                      placeholder="Entrez le montant minimun"
                      value={formik.values.unitAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  />
                  {formik.touched.unitAmount && formik.errors.unitAmount && (
                      <div className="text-sm text-red-500">
                        {formik.errors.unitAmount}
                      </div>
                  )}
                </div>
                <div className="mb-4 w-full md:w-1/2">
                  <label
                      htmlFor="totalAmount"
                      className="block mb-2 text-sm font-medium text-gray-900">
                    Montant total à atteindre (facultatif)
                  </label>
                  <input
                      type="number"
                      name="totalAmount"
                      id="totalAmount"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                      placeholder="Entrez le montant minimun"
                      value={formik.values.totalAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                  />
                  {formik.touched.totalAmount && formik.errors.totalAmount && (
                      <div className="text-sm text-red-500">
                        {formik.errors.totalAmount}
                      </div>
                  )}
                </div>
              </div>
            </div>
            <SubmitBtnWithLoader label="Modifier la cagnotte" status={updateCagnotteStatus} />
          </form>
        </div>
      </Modal>
    </>
  );
};

export default DetailsCagnotte;

