import {FaChevronLeft, FaChevronRight} from "react-icons/fa";

type PaginationComponentProps = {
  nPages: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
};

const PaginationComponent = ({
  nPages,
  currentPage,
  setCurrentPage,
}: PaginationComponentProps) => {
  const pageNumbers: number[] = Array.from({length: nPages}, (_, i) => i + 1);

  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };

  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="w-1/2 mx-auto mt-6">
      <div className="flex items-center justify-center">
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination">
            <button
              onClick={goToPrevPage}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Previous</span>
              <FaChevronLeft className="h-5 w-5" aria-hidden="true" />
            </button>
            {pageNumbers.map((pgNumber: number) => (
              <button
                key={pgNumber}
                onClick={() => setCurrentPage(pgNumber)}
                aria-current="page"
                className={`${
                  currentPage === pgNumber
                    ? "relative z-10 inline-flex items-center bg-corail-100 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                }`}>
                {pgNumber}
              </button>
            ))}
            <button
              onClick={goToNextPage}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Next</span>
              <FaChevronRight className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default PaginationComponent;
