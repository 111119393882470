import {createAsyncThunk} from "@reduxjs/toolkit";
import {updateUserPasswordCommand} from "../command/updateUserPassword.command";
import {axios} from "../../../../lib/axios";

export const updateUserPassword = createAsyncThunk(
  "updateUserPassword/auth",
  async (payload: updateUserPasswordCommand) => {
    const response = await axios.put("auth/password", payload);
    return response.status;
  }
);
