import {createSlice} from "@reduxjs/toolkit";
import {UpdateCagnotteState} from "../states/updateCagnotte.state";
import {updateCagnotteThunk} from "../thunk/updateCagnotte.thunk";


const initialState: UpdateCagnotteState = {
    cagnotte: "",
    status: "idle",
    error: null,
}

const updateCagnotteSlice = createSlice({
    name: 'updateCagnotteSlice',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(updateCagnotteThunk.pending, state => {
                state.status = "loading";
            })
            .addCase(updateCagnotteThunk.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.cagnotte = action.payload;
            })
            .addCase(updateCagnotteThunk.rejected, (state, action) => {
                state.error = action.error.message;
                state.status = "failed";
            })

    }
});

export default updateCagnotteSlice.reducer;
