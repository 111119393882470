import {createAsyncThunk} from "@reduxjs/toolkit";
import {axios} from "../../../../lib/axios";
import {OpenCagnotteCommand} from "../command/OpenCagnotteCommand";

export const openCagnotte = createAsyncThunk(
  "cagnotte/openClosedCagnotte",
  async (payload: OpenCagnotteCommand) => {
    const response = await axios.put(`/auth/jackpots/open`, {
      jackpot: payload.jackpot,
      expired: payload?.expired_at,
    });

    return response.data;
  }
);
