import {createAsyncThunk} from "@reduxjs/toolkit";
import {axios} from "../../../../lib/axios";

export const fetchAllCagnottes = createAsyncThunk(
  "cagnotte/fetchAllCagnottes",
  async (currentPage: number) => {
    const response = await axios.get(
      `jackpots/99b93824-8625-4546-98eb-97eebc37b172/all?page=${currentPage}`
    );

    return response.data;
  }
);
