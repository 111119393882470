import {createAsyncThunk} from "@reduxjs/toolkit";
import {axios} from "../../../../lib/axios";
import {GetUserCagnottesCommand} from "../command/GetUserCagnottesCommand";

export const fetchUserCagnottes = createAsyncThunk(
  "user/fetchCagnottes",
  async (currentPage: GetUserCagnottesCommand) => {
    const response = await axios.get(
      `auth/jackpots/99b93824-8625-4546-98eb-97eebc37b172?page=${currentPage.currentPage}`
    );

    return response.data;
  }
);
