import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux/hook";
import {RootState} from "../../redux/store";
import {useFormik} from "formik";
import * as Yup from "yup";
import {updateUserPasswordCommand} from "../../redux/features/updatePassword/command/updateUserPassword.command";
import Swal from "sweetalert2";
import {updateUserPassword} from "../../redux/features/updatePassword/thunk/updateUserPassword.thunk";
import {useEffect} from "react";
import {resetStatus} from "../../redux/features/update-user-infos/updateUserInfosSlice";
import Card from "../card/Card";
import PasswordInput from "../UIs/passwordInput/PasswordInput";
import {ThreeDots} from "react-loader-spinner";

const UserSecurity = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const updatePasswordStatus = useAppSelector(
        (state: RootState) => state.updateUserPassword.status
    );

    const passwordFormik = useFormik({
        initialValues: {
            old_password: "",
            password: "",
            password_confirmation: "",
        },
        validationSchema: Yup.object({
            old_password: Yup.string().required("Ce champ est obligatoire"),
            password: Yup.string()
                .required("Ce champ est obligatoire")
                .min(8, "Le mot de passe doit contenir au moins 8 caractères"),
            password_confirmation: Yup.string()
                .oneOf(
                    [Yup.ref("password"), ""],
                    "Les mots de passe doivent correspondre"
                )
                .required("Ce champ est obligatoire"),
        }),
        onSubmit: values => {
            updatePassword(values);
        },
    });

    const updatePassword = async (payload: updateUserPasswordCommand) => {
        Swal.fire({
            title: "Modification du mot de passe",
            text: "Êtes vous sûr de vouloir effectuer cette action ?",
            icon: "warning",
            iconColor: "#FF7F50",
            showCancelButton: true,
            confirmButtonColor: "#FF7F50",
            cancelButtonColor: "#d33",
            cancelButtonText: "Annuler",
            confirmButtonText: "Oui, modifier",
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(updateUserPassword(payload));
            }
        });
    };

    useEffect(() => {
        if (updatePasswordStatus === "failed") {
            Swal.fire({
                title: "Erreur",
                iconColor: "#d33",
                text: "Une erreur est survenue, veuillez vérifier les informations de mot de passe.",
                icon: "error",
                confirmButtonColor: "#FF7F50",
            }).then(() => {
                dispatch(resetStatus());
            });
        } else if (updatePasswordStatus === "succeeded") {
            Swal.fire({
                title: "Mot de passe modifié",
                iconColor: "#34d399",
                text: "Votre mot de passe a été modifié avec succès. Vous serez déconnecté et redirigé vers la page d'accueil.",
                icon: "success",
                confirmButtonColor: "#FF7F50",
            });
            setTimeout(() => {
                navigate("/auth/login");
            }, 2000);
        }
    }, [updatePasswordStatus]);
    return (
        <>
            <h1 className={`text-5xl`}>Sécurité</h1>
            <p className="mb-4">
                Vous pouvez modifier votre mot de passe en modifiant puis validant
                le formulaire ci-dessous
            </p>
            <Card>
                <form
                    action="#"
                    method="post"
                    onSubmit={passwordFormik.handleSubmit}>
                    <PasswordInput
                        label="Ancien mot de passe"
                        id={`old_password`}
                        name={`old_password`}
                        placeholder="Ancien mot de passe"
                        value={passwordFormik.values.old_password}
                        onChange={passwordFormik.handleChange}
                        onBlur={passwordFormik.handleBlur}
                        touched={passwordFormik.touched.old_password}
                        error={passwordFormik.errors.old_password}
                    />

                    <PasswordInput
                        label="Nouveau mot de passe"
                        id={`password`}
                        name={`password`}
                        placeholder="Nouveau mot de passe"
                        value={passwordFormik.values.password}
                        onChange={passwordFormik.handleChange}
                        onBlur={passwordFormik.handleBlur}
                        touched={passwordFormik.touched.password}
                        error={passwordFormik.errors.password}
                    />

                    <PasswordInput
                        label="Confirmez le mot de passe"
                        id={`password_confirmation`}
                        name={`password_confirmation`}
                        placeholder="Veuillez confirmez le mot de passe"
                        value={passwordFormik.values.password_confirmation}
                        onChange={passwordFormik.handleChange}
                        onBlur={passwordFormik.handleBlur}
                        touched={passwordFormik.touched.password_confirmation}
                        error={passwordFormik.errors.password_confirmation}
                    />
                    <button
                        type="submit"
                        className={`w-full bg-corail-100 hover:bg-corail-500 text-white p-2 rounded-md
                  ${
                            passwordFormik.isValid
                                ? "w-full bg-corail-100 hover:bg-corail-500 text-white p-2 rounded-md"
                                : "opacity-50 cursor-not-allowed"
                        }
                `}
                        disabled={!passwordFormik.isValid}>
                        {updatePasswordStatus === "loading" ? (
                            <div className="flex align-center justify-center">
                                <ThreeDots
                                    visible={true}
                                    height="24.5"
                                    color="#fff"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>
                        ) : (
                            "Modifier mon mot de passe"
                        )}
                    </button>
                </form>
            </Card>
        </>
    );
}

export default UserSecurity;
