import {createAsyncThunk} from "@reduxjs/toolkit";
import {axios} from "../../../../lib/axios";
import {GetCagnottesByTitleCommand} from "../command/GetCagnottesByTitleCommand";

export const getCagnotteByTitle = createAsyncThunk(
  "searchCagnotteByName/fetchCagnotte",
  async (payload: GetCagnottesByTitleCommand) => {
    const response = await axios.get(
      `jackpots/99b93824-8625-4546-98eb-97eebc37b172/${payload.title}/search/all?page=${payload.currentPage}` // Côte d'Ivoire
    );

    console.log(response.data);

    return response.data;
  }
);
