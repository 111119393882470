import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useAppSelector} from "../../../redux/hook";
import {RootState} from "../../../redux/store";
import {IoMdAddCircle} from "react-icons/io";

const CagnotteLink = () => {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const handleClick = () => {
    if (isAuthenticated) {
      navigate("/cagnotte/create");
    } else {
      toast.error(
        "Vous devez vous connecter avant la création de votre cagnotte.",
        {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '14px'
            }
        }
      );
      navigate("/auth/login");
    }
  };

  const copyToClipboard = () => {
    const link: HTMLInputElement | null = document.querySelector("#link");
    if (link) {
      navigator.clipboard.writeText(link.value);
      toast.success("Lien copié !", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '14px'
          }
      });
    } else {
      toast.error("Une erreur est survenue, veuillez reessayer svp.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '14px'
          }
      });
    }
  };

  return (
    <div className="link my-4 flex flex-col md:flex-row gap-3 items-center justify-between w-full">
      <input
        type="text"
        id="link"
        disabled
        value={window.location.href}
        className="hover:cursor-pointer w-full md:w-5/6 block bg-gray-50 border border-gray-100 text-gray-900 text-sm rounded-lg focus:border-none focus:outline-none p-4"
        required
      />
      <button
        onClick={copyToClipboard}
        className="text-white bg-corail-100 p-4 rounded-lg w-full md:w-1/6 hover:bg-corail-500">
        Copier
      </button>
      <button
        className="flex justify-center align-center text-white bg-corail-100 p-4 rounded-lg hover:bg-corail-500 w-full sm:inline-block sm:w-auto"
        onClick={handleClick}>
        <IoMdAddCircle className="text-2xl" />
      </button>
      <ToastContainer />
    </div>
  );
};

export default CagnotteLink;
