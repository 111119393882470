import HeroHeader from "../heroHeader/HeroHeader";
import Navbar from "../navbar/Navbar";
import cover from "./../../images/hero-image-cover-1.png";

const Header = () => {
  return (
    <header>
      <Navbar />
      <HeroHeader
        searchInfoPage="/cagnotte/cagnottes"
        img={cover}
        greatTitle="Pour toutes vos occasions, YoCagnotte est la solution."
        description="Rejoignez notre communauté et découvrez comment nous pouvons vous
        aider à atteindre vos objectifs, un pas à la fois."
      />
    </header>
  );
};

export default Header;
