import React, {useEffect, useRef, useState} from "react";
import CustomLink from "../UIs/customLink/CustomLink";
import {useAppDispatch, useAppSelector} from "../../redux/hook";
import {RootState} from "../../redux/store";
import {Link, useNavigate} from "react-router-dom";
import {
  getAuthenticatedUserInfos,
  logOutUser,
} from "../../redux/features/auth/thunk/login.thunk";
import extractInitials from "../../helpers/initialHelper";

// import {
//   getAuthenticatedUserInfos,
//   logOutUser,
// } from "@/redux/features/auth/thunk/login.thunk";
// import initialHelper from "@/app/helpers/initialHelper";

const Navbar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const userInfos = useAppSelector((state: RootState) => state.auth.userInfos);

  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("authToken");
      await dispatch(logOutUser(JSON.stringify(token)));
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la déconnexion :", error);
    }
    setDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current?.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAuthenticatedUserInfos());
    } else {
      localStorage.removeItem("authToken");
    }
  }, [isAuthenticated, dispatch]);

  return (
    <nav className="bg-gray-800 fixed w-full z-50 top-0">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-1">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <a href="/">
                <span className="text-corail-100 text-3xl sm:text-4xl lg:text-3xl text-primary">
                  YoCagnotte
                </span>
              </a>
            </div>
            <div className="hidden md:block lg:hidden">
              <div className="ml-10 flex items-baseline space-x-4">
                <a
                  href="/"
                  className="bg-corail-100 text-white px-3 py-2 rounded text-sm font-medium">
                  Accueil
                </a>
                <a
                  href="#"
                  className="text-white hover:bg-corail-100 hover:text-white px-3 py-2 rounded text-sm font-medium">
                  Contact
                </a>
              </div>
            </div>
          </div>
          <div className="hidden lg:flex space-x-4">
            {isAuthenticated ? (
              <div className="relative" ref={dropdownRef}>
                <div
                  onClick={() => setDropdownOpen(!isDropdownOpen)}
                  className="w-10 h-10 bg-corail-500 rounded-full flex items-center justify-center text-white font-bold text-lg cursor-pointer">
                  {extractInitials(
                    userInfos?.firstname + " " + userInfos?.lastname
                  )}
                </div>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-3 w-[200px] rounded-md shadow-lg bg-white divide-y divide-slate-200">
                    <div className="text-gray-800 hover:bg-corail-500 hover:text-white block px-3 py-2 text-base font-medium">
                      {userInfos?.firstname + " " + userInfos?.lastname}
                    </div>
                    <div className="">
                      <Link
                        to="/user/account"
                        className="text-gray-800 hover:bg-corail-500 hover:text-white block px-3 py-2 text-base font-medium"
                        onClick={() => {
                          setDropdownOpen(!isDropdownOpen);
                        }}>
                        Mon compte
                      </Link>
                      <Link
                        to="/user/my-cagnottes"
                        className="text-gray-800 hover:bg-corail-500 hover:text-white block px-3 py-2 text-base font-medium"
                        onClick={() => {
                          setDropdownOpen(!isDropdownOpen);
                        }}>
                        Mes cagnottes
                      </Link>
                      <a
                        href="#"
                        className="text-gray-800 hover:bg-corail-500 hover:text-white block px-3 py-2 text-base font-medium"
                        onClick={handleLogout}>
                        Déconnexion
                      </a>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                <CustomLink
                  link="/auth/login"
                  customClass="bg-corail-500 hover:bg-white hover:text-corail-100 text-white py-2 px-4 rounded font-medium">
                  Connexion
                </CustomLink>
                <CustomLink
                  link="/auth/register"
                  customClass="border-corail-500 border hover:bg-corail-500 hover:text-white text-corail-100 rounded py-2 px-4 font-medium">
                  Inscription
                </CustomLink>
              </>
            )}
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              className="text-white">
              <i className="fas fa-bars"></i>
            </button>
            {isMobileMenuOpen && (
              <div className="absolute top-full left-0 w-full bg-gray-800">
                {/* Votre menu mobile ici */}
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
