import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import categorieReducer from "./features/categories/categorieSlice";
import cagnotteReducer from "./features/cagnottes/slices/cagnotteSlice";
import cagnotteDetailsReducer from "./features/cagnottes/slices/cagnotteDetailsSlice";
import paginationReducer from "./features/pagination/paginationSlice";
import createCagnotteReducer from "./features/cagnottes/slices/createCagnotteSlice";
import messagesReducer from "./features/messages/slices/messagesSlice";
import userInfosReducer from "./features/user-infos/userInfosSlice";
import updatePasswordReducer from "./features/updatePassword/updatePasswordSlice";
import updateUserInfosReducer from "./features/update-user-infos/updateUserInfosSlice";
import mailResetReducer from "./features/mail-reset/mailResetSlice";
import payCagnotteReducer from "./features/pay/paySlice";
import TabsReducer from "./features/tabs/tabsSlice";
import modalReducer from "./features/modal/ModalSlice";
import updateCagnotteReducer from "./features/cagnottes/slices/updateCagnotteSlice";
import passwordTokenVerificationReducer from "./features/password-token-verification/passwordTokenVerificationSlice";
import resetPasswordReducer from "./features/reset-password/resetPasswordSlice";
import cinetpayPaymentReducer from "./features/pay/cinetpayPaymentSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    categories: categorieReducer,
    cagnottes: cagnotteReducer,
    cagnotteDetails: cagnotteDetailsReducer,
    createCagnotte: createCagnotteReducer,
    updateCagnotte: updateCagnotteReducer,
    pagination: paginationReducer,
    cagnottesExtra: messagesReducer,
    userInfos: userInfosReducer,
    updateUserInfos: updateUserInfosReducer,
    updateUserPassword: updatePasswordReducer,
    mailReset: mailResetReducer,
    payCagnotte: payCagnotteReducer,
    tabs: TabsReducer,
    modal: modalReducer,
    passwordTokenVerification: passwordTokenVerificationReducer,
    resetPassword: resetPasswordReducer,
    cinetpayPayment: cinetpayPaymentReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
