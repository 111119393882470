import {createSlice} from "@reduxjs/toolkit";
import {UpdatePasswordState} from "./updatePassword.state";
import {updateUserPassword} from "./thunk/updateUserPassword.thunk";

const initialState: UpdatePasswordState = {
  isUpdated: false,
  requestStatus: "",
  status: "idle",
  error: null,
};

const updatePasswordSlice = createSlice({
  name: "updatePassword",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(updateUserPassword.pending, state => {
        state.status = "loading";
      })
      .addCase(updateUserPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.requestStatus = action.payload.toString();
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default updatePasswordSlice.reducer;
