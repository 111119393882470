import {createSlice} from "@reduxjs/toolkit";
import {CinetpayPaymentState} from "./state/CinetpayPaymentState.state";
import {getPaymentUrl} from "./thunk/getPaymentUrl.thunk";

const initialState: CinetpayPaymentState = {
    data: {
        payment_token: "",
        payment_url: "",
    },
    status: "idle",
    error: null,
}

const cinetpayPaymentSlice = createSlice({
    name: "cinetpayPayment",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPaymentUrl.pending, state => {
            state.status = "loading";
        })
        .addCase(getPaymentUrl.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        })
        .addCase(getPaymentUrl.rejected, (state, action) => {
            state.error = action.error.message;
            state.status = "failed";
        })
    }
});

export default cinetpayPaymentSlice.reducer;