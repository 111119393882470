import {Link} from "react-router-dom";
import {Cagnotte} from "../../../models/Cagnotte";

type CagnotteComponentProps = {
  cagnotte: Cagnotte;
};

const CagnotteComponent = ({cagnotte}: CagnotteComponentProps) => {
  const percentage = (cagnotte.amount_paid / cagnotte.total_amount) * 100;
  return (
    <div className="w-full sm:w-full md:w-1/4 lg:w-1/4 xl:w-1/4 p-2">
      <Link to={`/cagnotte/details/${cagnotte.id}`}>
        <div className="shadow-lg rounded-b-lg">
          <img
            src={cagnotte.image}
            alt={cagnotte.title}
            className={`object-cover rounded-t-lg w-full h-40`}
            width={300}
          />
          <div className={`p-4 h-[161px]`}>
            <h2 className="font-bold text-lg py-3">{cagnotte.title}</h2>
            <div className="mb-2">
              Créé par <span className="font-bold">{cagnotte.firstname}</span>
            </div>
            <div className="text-xs">
              <span className="font-semibold">
                {cagnotte.amount_paid?.toLocaleString("fr-FR")} FCFA
              </span>
              {cagnotte.total_amount
                ? ` recoltés sur ${cagnotte.total_amount?.toLocaleString(
                    "fr-FR"
                  )} FCFA`
                : ""}
            </div>
            {cagnotte.total_amount ? (
              <div className="mt-7">
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className="bg-corail-100 h-2.5 rounded-full"
                    style={{width: percentage + "%"}}></div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CagnotteComponent;
