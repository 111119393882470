import {IoMdHome} from "react-icons/io";
import {FaArrowLeft} from "react-icons/fa";
import {Link, useNavigate} from "react-router-dom";

function BackNavButton() {
  const history = useNavigate();
  return (
    <div className="w-full my-5 flex gap-3 justify-between items-center">
      <button onClick={() => history(-1)} className="text-corail-100 text-2xl">
        <FaArrowLeft />
      </button>
      <Link to="/" className="text-corail-100 text-2xl">
        <IoMdHome />
      </Link>
    </div>
  );
}

export default BackNavButton;
