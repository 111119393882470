import {Route, Routes} from "react-router-dom";
import "./App.css";

import RootLayout from "./RootLayout";
import Home from "./pages/Home";
import CreateCagnotte from "./pages/cagnotte/create/CreateCagnotte";
import Login from "./pages/auth/login/Login";
import AuthLayout from "./pages/auth/AuthLayout";
import Register from "./pages/auth/register/Register";
import CagnotteLayout from "./pages/cagnotte/CagnotteLayout";
import DetailsCagnotte from "./pages/cagnotte/details/DetailsCagnotte";
import Cagnottes from "./pages/cagnotte/cagnottes/Cagnottes";
import ScrollToTop from "./components/UIs/scrollTop/ScrollTop";
import CagnottePay from "./pages/cagnotte/pay/CagnottePay";
import {ToastContainer} from "react-toastify";
import MyCagnottes from "./pages/auth/my-cagnottes/MyCagnottes";
import UserAccount from "./pages/auth/user-account/UserAccount";
import MailUser from "./pages/auth/reset-password/MailUser";
import PasswordReset from "./pages/auth/reset-password/PasswordReset";

function App() {
  return (
    <RootLayout>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="cagnotte" element={<CagnotteLayout />}>
          <Route path="cagnottes" element={<Cagnottes />} />
          <Route path="create" element={<CreateCagnotte />} />
          <Route path="details/:id" element={<DetailsCagnotte />} />
          <Route path=":id/participate" element={<CagnottePay />} />
        </Route>
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="mail-reset-password" element={<MailUser />} />
          <Route path="reset-password" element={<PasswordReset />} />
        </Route>
        <Route path="user" element={<CagnotteLayout />}>
          <Route path="my-cagnottes" element={<MyCagnottes />} />
          <Route path="account" element={<UserAccount />} />
        </Route>
      </Routes>
      <ToastContainer />
    </RootLayout>
  );
}

export default App;
