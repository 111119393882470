import React from "react";

interface CommentBlockProps {
  name?: string;
  published_at: string;
  comment?: string;
  amount?: number|string;
}

export default function ParticipantBlock({ name, published_at, comment, amount }: CommentBlockProps) {
  return (
      <div className="border-bottom bg-gray-100 mb-4">
          <div className="flex flex-col lg:flex-row">
              <div className="lg:w-2/3">
                  <span className="font-semibold username block">{name}</span>
                  <span className="publised_at block text-xs">{published_at}</span>
              </div>
              <div className="lg:w-1/3 text-end">
                  {amount}
              </div>
          </div>
          <div className="mt-2" style={{ whiteSpace: "pre-wrap" }}>{comment}</div>
    </div>
  );
}
