import {createSlice} from "@reduxjs/toolkit";
import {CagnotteDetailsState} from "../states/cagnotteDetails.state";
import {fetchCagnotteDetails} from "../thunk/fetchCagnotteDetails.thunk";

const initialState: CagnotteDetailsState = {
  details: null,
  currency: "",
  amount_paid: 0,
  cash_in: 0,
  owner: false,
  status: "idle",
  error: null,
};

const cagnotteDetailsSlice = createSlice({
  name: "cagnotteDetails",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCagnotteDetails.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchCagnotteDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.details = action.payload.details;
        state.owner = action.payload.owner;
        state.currency = action.payload.currency;
        state.amount_paid = action.payload.amount_paid;
        state.cash_in = action.payload.cash_in;
      })
      .addCase(fetchCagnotteDetails.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default cagnotteDetailsSlice.reducer;
