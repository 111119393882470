import HeroHeader from "../../../components/heroHeader/HeroHeader";
import cover from "../../../images/profile-banner.png";
import BackNavButton from "../../../components/UIs/backHome/BackNavButton";
import {useAppDispatch} from "../../../redux/hook";
import {useEffect} from "react";
import Tabs from "../../../components/tabs/Tabs";
import {setActiveTab} from "../../../redux/features/tabs/tabsSlice";
import UserProfile from "../../../components/user-profile/UserProfile";
import UserSecurity from "../../../components/user-security/UserSecurity";

const UserAccount = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveTab('Profil'))
  }, []);

  const tabs = [
    {
      label: 'Profil',
      content: <UserProfile />
    },
    {
      label: 'Sécurité',
      content: <UserSecurity />
    },
    { label: 'Tab3', content: <div>Content for Tab 3 on Page 2</div> },
  ];

  return (
    <>
      <div className="mt-16">
        <HeroHeader
          img={cover}
          greatTitle="Mon compte"
          description="Paramètres de votre compte : les informations entrées doivent figurer sur votre Carte d'identité."
        />
      </div>
      <div className="w-1/2 justify-center flex">

      </div>
      <div className="flex justify-center mt-8">
        <div className="w-full sm:w-1/2 lg:w-2/4">
          <BackNavButton />
          <Tabs tabs={tabs} />
        </div>
      </div>
    </>
  );
};

export default UserAccount;
