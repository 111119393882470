import {createAsyncThunk} from "@reduxjs/toolkit";

import {LoginCommand} from "../command/login.command";
import {axios} from "../../../../lib/axios";

export const authenticateUser = createAsyncThunk(
  "user/auth",
  async (values: LoginCommand) => {
    const response = await axios.post("/login", {
      account: values.account,
      password: values.password,
    });
    localStorage.setItem("authToken", response.data);
    return response.data;
  }
);

export const getAuthenticatedUserInfos = createAsyncThunk(
  "user/getAuthenticatedUser",
  async () => {
    const response = await axios.get("/auth/profile");
    return response.data;
  }
);

export const logOutUser = createAsyncThunk(
  "user/logout",
  async (token: string) => {
    const response = await axios.post("/auth/logout", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  }
);
