import {FaSearch} from "react-icons/fa";
import {useAppDispatch, useAppSelector} from "../../../redux/hook";
import {ChangeEvent, useEffect, useState} from "react";
import {getAllCategories} from "../../../redux/features/categories/thunk/getAllCategoriesThunk";
import {RootState} from "../../../redux/store";
import {Category} from "../../../models/Category";
import {getCagnottesByCategories} from "../../../redux/features/cagnottes/thunk/getCagnottesByCategoriesThunk";
import {useNavigate} from "react-router-dom";
import {getCagnotteByTitle} from "../../../redux/features/cagnottes/thunk/searchCagnotteByTitle.thunk";

interface SearchProps {
  pageResult: string | null;
}

const Search = ({pageResult}: SearchProps) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [categoryId, setCategoryId] = useState<string>("");
  const categories = useAppSelector(
    (state: RootState) => state.categories.categories
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      dispatch(getCagnotteByTitle({title: searchTerm, currentPage: 1}));
      navigate(`${pageResult}?query=${searchTerm}`);
    }
  };

  const handleGetCagnotteByCategory = (payload: {
    categoryId: string;
    currentPage: number;
  }) => {
    if (payload.categoryId) {
      setCategoryId(payload.categoryId);
    }
    dispatch(getCagnottesByCategories(payload));
    navigate(`${pageResult}`);
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-6 bg-white">
      <div className="relative shadow-xl">
        <input
          type="text"
          name="search"
          className="w-full py-4 px-10 rounded focus:ring-2 focus:outline-none focus:ring-corail-500 border-0"
          placeholder="Tapez la touche Entrer pour lancer la recherche"
          value={searchTerm}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          <FaSearch className="text-corail-100" />
        </span>
      </div>

      <div className="mt-7">
        <h3 className="text-lg font-semibold mb-2">Catégories</h3>
        <div className="flex flex-wrap gap-2">
          {categories.map((category: Category) => (
            <button
              key={category.id}
              className={`${
                categoryId === category.id
                  ? "inline-block mb-2 rounded-full px-4 py-1 bg-corail-100 text-white"
                  : "inline-block mb-2 bg-gray-100 text-black-500 hover:bg-corail-100 hover:text-white rounded-full px-4 py-1"
              }`}
              onClick={() =>
                handleGetCagnotteByCategory({
                  categoryId: category.id,
                  currentPage: 1,
                })
              }>
              {category.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Search;
