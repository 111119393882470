import {useFormik} from "formik";
import * as Yup from "yup";
import CustomLink from "../../../components/UIs/customLink/CustomLink";
import BackNavButton from "../../../components/UIs/backHome/BackNavButton";
import {useAppDispatch, useAppSelector} from "../../../redux/hook";
import {RootState} from "../../../redux/store";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {registerUser} from "../../../redux/features/auth/thunk/register.thunk";
import bgImage from "./../../../images/sign-up-img-2.jpeg";
import Swal from "sweetalert2";
import SubmitBtnWithLoader from "../../../components/UIs/submitBtnWithLoader/SubmitBtnWithLoader";
import {reinitializeStatus} from "../../../redux/features/auth/authSlice";
// import {useAppSelector} from "../../../redux/hook";
// import {RootState} from "../../../redux/store";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSuccess = useAppSelector((state: RootState) => state.auth.status);
  const token = useAppSelector((state: RootState) => state.auth.token);

  const formik = useFormik({
    initialValues: {
      lastname: "",
      firstname: "",
      birthday: "",
      username: "",
      email: "",
      phone: "",
      city: "",
      occupation: "",
      gender: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      lastname: Yup.string().required("Ce champ est obligatoire"),
      firstname: Yup.string().required("Ce champ est obligatoire"),
      birthday: Yup.date()
        .max(new Date(), "La date de naissance ne peut pas être dans le futur")
        .required("Ce champ est obligatoire")
        .test("is-adult", "Vous devez avoir au moins 18 ans", function (value) {
          const cutoffDate = new Date();
          cutoffDate.setFullYear(cutoffDate.getFullYear() - 18);
          return new Date(value) <= cutoffDate;
        }),
      username: Yup.string().required("Ce champ est obligatoire"),
      email: Yup.string()
        .email("L'adresse e-mail n'est pas valide")
        .required("Ce champ est obligatoire")
        .test("email", "L'adresse e-mail n'est pas valide", function (value) {
          if (!value) return true;
          return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value);
        }),
      phone: Yup.string()
          .required("Ce champ est obligatoire")
          .min(10, "Veuillez entrer un numéro de téléphone valide")
          .max(10, "Veuillez entrer un numéro de téléphone valide"),
      city: Yup.string().required("Ce champ est obligatoire"),
      occupation: Yup.string().required("Ce champ est obligatoire"),
      gender: Yup.string().required("Ce champ est obligatoire"),
      password: Yup.string()
        .required("Ce champ est obligatoire")
        .min(8, "Le mot de passe doit contenir au moins 8 caractères"),
      password_confirmation: Yup.string()
        .oneOf(
          [Yup.ref("password"), ""],
          "Les mots de passe doivent correspondre"
        )
        .required("Ce champ est obligatoire"),
    }),
    onSubmit: values => {
      dispatch(registerUser(values));
    },
  });

  useEffect(() => {
    if (isSuccess === "succeeded") {
      localStorage.setItem("authToken", token);
      navigate("/");
    } else if (isSuccess === "failed") {
      Swal.fire({
        title: "Erreur",
        iconColor: "#d33",
        text: "Une erreur est survenue, veuillez reessayer svp",
        icon: "error",
        confirmButtonColor: "#FF7F50",
      }).then(() => {
        dispatch(reinitializeStatus());
      });
    } else {
      return;
    }
  }, [isSuccess]);

  return (
    <>
      <div
        className="w-full md:w-2/3 bg-cover"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
        }}></div>
      <div className="w-full md:w-1/3 p-4 md:p-12 overflow-y-auto max-h-screen">
        <BackNavButton />
        <h1 className="font-bold text-4xl mb-6">S&apos;inscrire</h1>
        <form className="w-full" onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor={`firstname`}
              className="block text-sm font-medium text-gray-600">
              Nom
            </label>
            <input
              className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
              type="text"
              id={`firstname`}
              name={`firstname`}
              placeholder="Nom"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstname}
            />
            {formik.touched.firstname && formik.errors.firstname ? (
              <div className="text-red-500">{formik.errors.firstname}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label
              htmlFor="lastname"
              className="block text-sm font-medium text-gray-600">
              Prénoms
            </label>
            <input
              className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
              type="text"
              id={`lastname`}
              name={`lastname`}
              placeholder="Prénoms"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastname}
            />
            {formik.touched.firstname && formik.errors.firstname ? (
              <div className="text-red-500">{formik.errors.firstname}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label
              htmlFor={`birthday`}
              className="block text-sm font-medium text-gray-600">
              Date de naissance
            </label>
            <input
              className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
              type="date"
              id={`birthday`}
              name={`birthday`}
              placeholder="Date de naissance"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.birthday}
            />
            {formik.touched.birthday && formik.errors.birthday ? (
              <div className="text-red-500">{formik.errors.birthday}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-600">
              Email
            </label>
            <input
              className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
              type="text"
              id={`email`}
              name={`email`}
              placeholder="Adresse email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label
              htmlFor={`username`}
              className="block text-sm font-medium text-gray-600">
              Pseudo
            </label>
            <input
              className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
              type="text"
              id={`username`}
              name={`username`}
              placeholder="Pseudonyme"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
            />
            {formik.touched.username && formik.errors.username ? (
              <div className="text-red-500">{formik.errors.username}</div>
            ) : null}
          </div>
          <div className="mb-4 flex gap-3 items-center">
            <p>Vous êtes</p>
            <input
              id="male"
              type="radio"
              name="gender"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value="M"
              checked={formik.values.gender === "M"}
            />
            <label htmlFor="male" className="text-corail-500">
              Homme
            </label>

            <input
              id="female"
              type="radio"
              name="gender"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value="F"
              checked={formik.values.gender === "F"}
            />
            <label htmlFor="female" className="text-corail-500">
              Femme
            </label>

            <input
              id="other"
              type="radio"
              name="gender"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value="O"
              checked={formik.values.gender === "O"}
            />
            <label htmlFor="other" className="text-corail-500">
              Autre
            </label>

            {formik.touched.gender && formik.errors.gender && (
              <div className="text-red-500">{formik.errors.gender}</div>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor={`phone`}
              className="block text-sm font-medium text-gray-600">
              Téléphone
            </label>
            <input
              className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
              type="tel"
              id={`phone`}
              name={`phone`}
              placeholder="Téléphone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className="text-red-500">{formik.errors.phone}</div>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor={`city`}
              className="block text-sm font-medium text-gray-600">
              Ville
            </label>
            <input
              className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
              type="tel"
              id={`city`}
              name={`city`}
              placeholder="Téléphone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
            />
            {formik.touched.city && formik.errors.city && (
              <div className="text-red-500">{formik.errors.city}</div>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor={`occupation`}
              className="block text-sm font-medium text-gray-600">
              Profession
            </label>
            <input
              className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
              type="text"
              id={`occupation`}
              name={`occupation`}
              placeholder="Profession"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.occupation}
            />
            {formik.touched.occupation && formik.errors.occupation && (
              <div className="text-red-500">{formik.errors.occupation}</div>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor={`password`}
              className="block text-sm font-medium text-gray-600">
              Mot de passe
            </label>
            <input
              id={`password`}
              name={`password`}
              className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
              type="password"
              placeholder="Mot de passe"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500">{formik.errors.password}</div>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor={`password_confirmation`}
              className="block text-sm font-medium text-gray-600">
              Confirmez le mot de passe
            </label>
            <input
              id={`password_confirmation`}
              name={`password_confirmation`}
              className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
              type="password"
              placeholder="Veuillez confirmez le mot de passe"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password_confirmation}
            />
            {formik.touched.password_confirmation &&
              formik.errors.password_confirmation && (
                <div className="text-red-500">
                  {formik.errors.password_confirmation}
                </div>
              )}
          </div>
          <SubmitBtnWithLoader label="S'inscrire" status={isSuccess} />
        </form>

        <div className="w-full mt-4">
          <CustomLink
            link="/auth/login"
            customClass="block text-center border-corail-100 border hover:bg-corail-100 hover:text-white text-corail-100 rounded py-2 px-4 font-medium">
            J&apos;ai déjà un compte
          </CustomLink>
        </div>
      </div>
    </>
  );
};

export default Register;
