import {createSlice} from "@reduxjs/toolkit";
import {CategorieState} from "./categorie.state";
import {getAllCategories} from "./thunk/getAllCategoriesThunk";

const initialState: CategorieState = {
  categories: [],
  status: "idle",
  error: null,
};

const categorieSlice = createSlice({
  name: "categorie",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllCategories.pending, state => {
        state.status = "loading";
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.categories = action.payload;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default categorieSlice.reducer;
