import {createSlice} from "@reduxjs/toolkit";
import PasswordTokenVerifyState from "./PasswordTokenVerify.state";
import {passwordTokenVerifyThunk} from "./thunk/passwordTokenVerify.thunk";

const initialState: PasswordTokenVerifyState = {
    tokenStatus: '',
    status: "idle",
    error: null,
};

const passwordTokenVerificationSlice = createSlice({
    name: "passwordTokenVerification",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(passwordTokenVerifyThunk.pending, state => {
                state.status = "loading";
            })
            .addCase(passwordTokenVerifyThunk.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.tokenStatus = action.payload.status;
            })
            .addCase(passwordTokenVerifyThunk.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});


export default passwordTokenVerificationSlice.reducer;