import {createSlice} from "@reduxjs/toolkit";
import {MailResetState} from "./mail-reset.state";
import {mailReset} from "./thunk/mailReset.thunk";

const initialState: MailResetState = {
  status: "idle",
  error: null,
};

const mailResetSlice = createSlice({
  name: "updatePassword",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(mailReset.pending, state => {
        state.status = "loading";
      })
      .addCase(mailReset.fulfilled, state => {
        state.status = "succeeded";
      })
      .addCase(mailReset.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default mailResetSlice.reducer;
