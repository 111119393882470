import {useAppDispatch, useAppSelector} from "../../redux/hook";
import {RootState} from "../../redux/store";
import {ReactNode} from "react";
import {setActiveTab} from "../../redux/features/tabs/tabsSlice";

interface TabContent {
    label: string;
    content: ReactNode
}

interface TabsProps {
    tabs: TabContent[];
}

const Tabs = ({ tabs }: TabsProps) => {
    const dispatch = useAppDispatch();
    const activeTab = useAppSelector((state: RootState) => state.tabs.activeTab);

    const handleTabClick = (tab: string) => {
        dispatch(setActiveTab(tab));
    }

    return (
        <div className="w-full max-w-2xl mx-auto">
            <div className="flex flex-wrap border-b-2 border-gray-300 mb-4">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        onClick={() => handleTabClick(tab.label)}
                        className={`px-4 py-2 text-gray-600 focus:outline-none ${
                            activeTab === tab.label
                                ? 'border-b-2 border-[#ff7f50] text-[#ff7f50]'
                                : 'hover:text-[#ff7f50] transition duration-300'
                        }`}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className="tab-content py-4">
                {tabs.map(
                    (tab, index) =>
                        activeTab === tab.label && <div key={index}>{tab.content}</div>
                )}
            </div>
        </div>
    );
}

export default Tabs;