import {createAsyncThunk} from "@reduxjs/toolkit";
import {MailResetCommand} from "../command/mailResteCommand";
import {axios} from "../../../../lib/axios";

export const mailReset = createAsyncThunk(
  "mailReset/auth",
  async (payload: MailResetCommand) => {
    const response = await axios.post("reset-password/init", payload);
    return response.data;
  }
);
