import {useState} from "react";
import Swal from "sweetalert2";

interface FileUploadBtnProps {
  field: {
    name: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
  form: {
    setFieldValue: (field: string, value: File) => void;
  };
  label: string;
}
const FileUploadBtn = ({field, form, label}: FileUploadBtnProps) => {
  const [preview, setPreview] = useState<string | undefined>(undefined);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] as File;
    if (file) {
      if (!file.type.startsWith("image/")) {
        Swal.fire({
          title: "Erreur",
          iconColor: "#d33",
          text: "Vous ne pouvez uploader que des images.",
          icon: "error",
          confirmButtonColor: "#FF7F50",
        }).then(() => {
          e.target.value = "";
        });
        return;
      }

      const maxSize = 2 * 1024 * 1024;
      if (file.size > maxSize) {
        Swal.fire({
          title: "Erreur",
          iconColor: "#d33",
          text: "La taille du fichier ne peut pas dépasser 2 Mo.",
          icon: "error",
          confirmButtonColor: "#FF7F50",
        }).then(() => {
          e.target.value = "";
          setPreview(undefined);
        });
      }

      form.setFieldValue(field.name, file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
      setPreview(undefined);
    }
  };

  return (
    <>
      {preview && (
        <img
          src={preview}
          alt="Image Preview"
          className="mt-2 max-w-full h-[250px] mx-auto mb-4"
        />
      )}
      <label
        htmlFor="file-upload"
        className="cursor-pointer text-center p-2 bg-corail-500/20 text-corail-500 font-semibold w-full block rounded">
        {label}
      </label>
      <input
        id="file-upload"
        type="file"
        name="image"
        accept="image/*"
        className="hidden"
        onChange={handleChange}
      />
      <p className="mt-1 text-sm text-gray-500" id="file_input_help">
        SVG, PNG, JPG (MAX 2Mo).
      </p>
    </>
  );
};

export default FileUploadBtn;
