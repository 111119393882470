import {createAsyncThunk} from "@reduxjs/toolkit";
import {axios} from "../../../../lib/axios";

export const fetchThreeLastCagnottes = createAsyncThunk(
  "cagnottes/fetch",
  async () => {
    const response = await axios.get(
      "jackpots/99b93824-8625-4546-98eb-97eebc37b172/last" // Côte d'Ivoire
    );
    return response.data;
  }
);
