import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../redux/hook";
import {RootState} from "../../redux/store";
import Button from "../UIs/button/Button";
import Search from "../UIs/search/Search";
import {IoMdAddCircle} from "react-icons/io";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {ThreeDots} from "react-loader-spinner";

interface HeroHeaderProps {
  img?: string;
  greatTitle?: string;
  description?: string;
  searchInfoPage?: string;
  isLoading?: boolean;
}

export default function HeroHeader({
  img,
  greatTitle,
  description,
  searchInfoPage,
  isLoading,
}: HeroHeaderProps) {
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const navigate = useNavigate();

  const handleClick = () => {
    if (isAuthenticated) {
      navigate("/cagnotte/create");
    } else {
      toast.error(
        "Vous devez vous connecter avant la création de votre cagnotte.",
        {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '14px'
            }
        }
      );
      navigate("/auth/login");
    }
  };

  return (
    <section>
      <div className="relative w-full h-[600px] flex items-center justify-center">
          {isLoading && isLoading ? (
              <div className="flex align-center justify-center">
                  <ThreeDots
                      visible={true}
                      height="24.5"
                      color="#ff7f50"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                  />
              </div>
          ) : (
              <>
                  <img src={img} alt="Hero" className="object-cover h-full w-full"/>
                  <div className="absolute text-white text-center w-full md:w-1/2 lg:w-1/3">
                      <h1 className="text-4xl md:text-5xl lg:text-4xl font-bold mb-3">
                          {greatTitle}
                      </h1>
                      <p className="text-sm md:text-base lg:text-lg">{description}</p>
                      <div className="flex justify-center mt-10">
                          <Button
                              classButton="bg-corail-100 hover:bg-corail-500 font-semibold px-8 py-4 gap-1 flex justify-center align-center"
                              click={handleClick}>
                              <IoMdAddCircle className="text-2xl"/> Créer une cagnotte
                          </Button>
                      </div>
                  </div>
              </>
          )}
      </div>
        {searchInfoPage ? <Search pageResult={searchInfoPage}/> : null}
    </section>
  );
}
