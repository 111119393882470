import {createAsyncThunk} from "@reduxjs/toolkit";
import {axios} from "../../../../lib/axios";

export const getUserInfos = createAsyncThunk(
  "getUserInfos/userInfos",
  async () => {
    const response = await axios.get("auth/profile");
      if (!(localStorage.getItem("authToken")) || localStorage.getItem("authToken") === null) {
          window.location.href = "/home"
          return;
      } else {
        return response.data;
      }
  }
);
