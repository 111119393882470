const Footer = () => {
  return (
    <footer>
      <section className="py-10 bg-gray-800 text-white w-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-1 flex justify-center">
          <div className="w-1/3 p-2">
            <span className="text-5xl text-corail font-semibold">
              YoCagnotte
            </span>
            <div className="font-4xl mt-4 font-semibold">
              Accélérez la réalisation de vos rêves.
            </div>
          </div>
          <div className="w-1/3 p-2">
            <span className="text-corail font-bold">Informations</span>
            <div className="mt-4">
              <ul className="mt-3 space-y-4 text-white">
                <li>
                  <a
                    href="@/components/partials/footer/Footer#"
                    className="mr-4 hover:underline md:mr-6 ">
                    A propos de nous
                  </a>
                </li>
                <li>
                  <a
                    href="@/components/partials/footer/Footer#"
                    className="hover:underline">
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    href="@/components/partials/footer/Footer#"
                    className="mr-4 hover:underline md:mr-6">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-1/3 p-2">
            <span className="text-corail font-bold">Informations</span>
            <div className="mt-4">
              <ul className="mt-3 space-y-4 text-white">
                <li>
                  <a
                    href="@/components/partials/footer/Footer#"
                    className="mr-4 hover:underline md:mr-6 ">
                    A propos de nous
                  </a>
                </li>
                <li>
                  <a
                    href="@/components/partials/footer/Footer#"
                    className="hover:underline">
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    href="@/components/partials/footer/Footer#"
                    className="mr-4 hover:underline md:mr-6">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="w-full text-white bg-corail-100 p-4">
        <div className="max-w-screen-xl mx-auto flex justify-between">
          <span className="text-sm text-white sm:text-center">
            © 2023{" "}
            <a href="https://yocagnotte.com/" className="hover:underline">
              YoCagnotte™
            </a>
            . All Rights Reserved.
          </span>
          <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-white sm:mt-0">
            <li>
              <a
                href="@/components/partials/footer/Footer#"
                className="mr-4 hover:underline md:mr-6 ">
                A propos de nous
              </a>
            </li>
            <li>
              <a
                href="@/components/partials/footer/Footer#"
                className="mr-4 hover:underline md:mr-6">
                Politique de confidentialité
              </a>
            </li>
            <li>
              <a
                href="@/components/partials/footer/Footer#"
                className="mr-4 hover:underline md:mr-6">
                License
              </a>
            </li>
            <li>
              <a
                href="@/components/partials/footer/Footer#"
                className="hover:underline">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
