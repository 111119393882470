import {useFormik} from "formik";
import * as Yup from "yup";
import BackNavButton from "../../../components/UIs/backHome/BackNavButton";
import FileUploadBtn from "../../../components/UIs/fileUploadBtn/FileUploadBtn";
import Switch from "../../../components/UIs/switch/switch/switch";
// import SwitchInput from "../../../components/UIs/switch/switchInput/SwitchInput";
// import Button from "../../../components/UIs/button/Button";
import {useAppDispatch, useAppSelector} from "../../../redux/hook";
import {RootState} from "../../../redux/store";
import {useEffect} from "react";
import {getAllCategories} from "../../../redux/features/categories/thunk/getAllCategoriesThunk";
import {Category} from "../../../models/Category";
import {createCagnotte} from "../../../redux/features/cagnottes/thunk/createCagnotte.thunk";
import {CreateCagnotteCommand} from "../../../redux/features/cagnottes/command/CreateCagnotteCommand";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import SubmitBtnWithLoader from "../../../components/UIs/submitBtnWithLoader/SubmitBtnWithLoader";
import Swal from "sweetalert2";
import {reinitializeStatus} from "../../../redux/features/auth/authSlice";
import {convertImageToBase64} from "../../../helpers/functionsHelper";

const CreateCagnotte = () => {
  const navigate = useNavigate();
  const cagnotte = useAppSelector(
    (state: RootState) => state.createCagnotte.cagnotte
  );
  const statut = useAppSelector((state: RootState) => state.createCagnotte.status);
  const dispatch = useAppDispatch();
  const categories = useAppSelector(
    (state: RootState) => state.categories.categories
  );
  useEffect(() => {
    dispatch(getAllCategories());
  }, []);
  const formik = useFormik({
    initialValues: {
      title: "",
      type: "",
      expired_at: "",
      image: null,
      description: "",
      unitAmount: "",
      totalAmount: "",
      statusPrivate: false,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .min(
          3,
          "Le nom de la cagnotte doit être d'au moins trois (3) caractères"
        )
        .required("Ce champs est obligatoire"),
      type: Yup.string().required("Vous devez ajouter une catégorie"),
      expired_at: Yup.date().min(
        new Date(),
        "La date d'expiration ne peut être dans le passé."
      ),
      image: Yup.mixed()
        .test("fileSize", "Fichier trop volumineux", value => {
          if (!value) return false;
          return (value as File).size <= 2 * 1024 * 1024;
        })
        .required("Vous devez ajouter une image"),
      description: Yup.string()
          .required("Vous devez ajouter une petite description de votre projet"),
      unitAmount: Yup.number(),
      totalAmount: Yup.number(),
    }),
    onSubmit: async values => {
      if (values.image) {
        const image = await convertImageToBase64(values.image);
        const payload: CreateCagnotteCommand = {
          type: values.type,
          country: "99b93824-8625-4546-98eb-97eebc37b172",
          title: values.title,
          image: image as string,
          description: values.description,
          expired_at: values.expired_at,
          unit_amount: Number(values.unitAmount),
          total_amount: Number(values.totalAmount),
          private: values.statusPrivate,
        };
        dispatch(createCagnotte(payload));
      }
    },
  });

  useEffect(() => {
    if (statut === "succeeded" && cagnotte) {
      toast.success("Votre cagnotte a été créé avec succès !", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '14px'
        }
      });
      navigate("/cagnotte/details/" + cagnotte.id);
    } else if (statut === "failed") {
      Swal.fire({
        title: "Données invalide",
        iconColor: "#d33",
        text: "Une erreur est survenue, veuillez réessayer",
        icon: "error",
        confirmButtonColor: "#FF7F50",
      }).then(() => {
        dispatch(reinitializeStatus());
      });
    } else {
      return;
    }
  }, [statut, cagnotte]);

  return (
    <div className="w-full md:w-2/5 md:p-0 p-4 mx-auto">
      <div className="mt-28">
        <BackNavButton />
      </div>
      <h1 className="text-center text-4xl text-corail-100 font-bold mb-6">
        Création d&apos;une cagnotte
      </h1>
      <div className="border rounded-md p-6">
        <h2 className="font-semibold text-xl text-gray-800 ">
          Informations sur la cagnotte
        </h2>
        <div className="mt-6">
          <form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
            <div className="mb-6">
              <label
                htmlFor="title"
                className="block mb-2 text-sm font-medium text-gray-900">
                Nom de la cagnotte
              </label>
              <input
                type="text"
                id="title"
                name="title"
                className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:outline-none focus:ring-2 focus:ring-corail-500 block w-full p-2.5`}
                placeholder="Anniversaire de..."
                required
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.title && formik.errors.title && (
                <div className="text-sm text-red-500">
                  {formik.errors.title}
                </div>
              )}
            </div>
            <div className="mb-6">
              <label
                htmlFor="type"
                className="block mb-2 text-sm font-medium text-gray-900">
                Catégorie
              </label>
              <select
                name="type"
                id="type"
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}>
                <option value="#">Choisir une catégorie</option>
                {categories.map((category: Category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              {formik.touched.type && formik.errors.type && (
                <div className="text-sm text-red-500">{formik.errors.type}</div>
              )}
            </div>
            <div className="mb-6">
              <label
                htmlFor="expired_at"
                className="block mb-2 text-sm font-medium text-gray-900">
                Ajouter une date d&apos;expiration (facultatif)
              </label>
              <input
                type="date"
                id="expired_at"
                name="expired_at"
                // min={new Date().toISOString().split("T")[0]}
                min={
                  new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split("T")[0]
                }
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                value={formik.values.expired_at}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.expired_at && formik.errors.expired_at && (
                <div className="text-sm text-red-500">
                  {formik.errors.expired_at}
                </div>
              )}
            </div>
            <div className="mb-6">
              <FileUploadBtn
                label="Choisir un fichier"
                field={formik.getFieldProps("image")}
                form={formik}
              />
              {formik.touched.image && formik.errors.image && (
                <div className="text-sm text-red-500">
                  {formik.errors.image}
                </div>
              )}
            </div>

            <div className="mb-6">
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900">
                Description
              </label>
              <textarea
                name="description"
                id="description"
                cols={30}
                rows={10}
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}></textarea>
              {formik.touched.description && formik.errors.description && (
                <div className="text-sm text-red-500">
                  {formik.errors.description}
                </div>
              )}
            </div>

            <Switch
              title="Rendre la cagnotte privée"
              description="Votre cagnotte ne sera pas accessible à tous les utilisateurs"
              name="statusPrivate"
              field={formik.getFieldProps("statusPrivate")}
              form={formik}
            />

            <div className="mb-4">
              <label
                htmlFor="unitAmount"
                className="block mb-2 text-sm font-medium text-gray-900">
                Montant minimum (facultatif)
              </label>
              <input
                type="number"
                id="unitAmount"
                name="unitAmount"
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                placeholder="Entrez le montant minimun"
                value={formik.values.unitAmount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.unitAmount && formik.errors.unitAmount && (
                <div className="text-sm text-red-500">
                  {formik.errors.unitAmount}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="totalAmount"
                className="block mb-2 text-sm font-medium text-gray-900">
                Montant total à atteindre (facultatif)
              </label>
              <input
                type="number"
                name="totalAmount"
                id="totalAmount"
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                placeholder="Entrez le montant minimun"
                value={formik.values.totalAmount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.totalAmount && formik.errors.totalAmount && (
                <div className="text-sm text-red-500">
                  {formik.errors.totalAmount}
                </div>
              )}
              {/* <SwitchInput
                title="Définir le montant à atteindre"
                checkboxName="total_amount"
                inputName="totalAmount"
                field={formik.getFieldProps("totalAmount")}
                form={formik}
              /> */}
            </div>

            <div>
              {/*<Button*/}
              {/*  type="submit"*/}
              {/*  classButton="block text-white bg-corail-100 hover:bg-corail-500 focus:ring-4 focus:outline-none focus:ring-corail-500 font-medium rounded-md text-sm w-full px-5 py-2.5 text-center">*/}
              {/*  Créer la cagnotte*/}
              {/*</Button>*/}
              <SubmitBtnWithLoader label="Créer la cagnotte" status={statut} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateCagnotte;
