"use client";

import React, {useState} from "react";
import {Link} from "react-router-dom";

type CustomLink = {
  children: React.ReactNode;
  link?: string;
  customClass?: string;
};

const CustomLink = ({children, link, customClass}: CustomLink) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const click = () => {
    setIsLoading(!isLoading);
  };

  return (
    <Link
      to={link ?? "#"}
      className={`${customClass} focus:outline-none focus:ring-4 focus:ring-corail-500`}
      onClick={click}>
      {children}
    </Link>
  );
};

export default CustomLink;
