import React from "react";
import {ThreeDots} from "react-loader-spinner";

interface SubmitBtnWithLoaderProps {
  status: string;
  label: string;
  isDisabled?: boolean;
}

function SubmitBtnWithLoader({status, label, isDisabled}: SubmitBtnWithLoaderProps) {
  return (
    <button
      type="submit"
      className="w-full bg-corail-100 hover:bg-corail-500 text-white p-2 rounded-md"
      disabled={isDisabled ?? isDisabled}
    >
      {status === "loading" ? (
        <div className="flex align-center justify-center">
          <ThreeDots
            visible={true}
            height="24.5"
            color="#fff"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        label
      )}
    </button>
  );
}

export default SubmitBtnWithLoader;
