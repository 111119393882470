import styles from "./../switch.module.css";

interface SwitchProps {
  title: string;
  description: string;
  name: string;
  field: {
    value: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  };
  form: {
    setFieldValue: (
      field: string,
      value: boolean,
      shouldValidate?: boolean
    ) => void;
  };
}

const Switch = ({title, description, name, field, form}: SwitchProps) => {
  return (
    <div className="w-full mb-4">
      <label
        htmlFor={name}
        className="flex items-start justify-between cursor-pointer">
        <div className="me-3 text-gray-700 font-medium">
          {title} <br />
          <span className="text-xs">
            {description}
          </span>
        </div>
        <div className="relative">
          <input
            type="checkbox"
            id={name}
            className="sr-only"
            name={name}
            onChange={() => form.setFieldValue(name, !field.value)}
            onBlur={field.onBlur}
            checked={field.value}
          />
          <div className="block bg-corail-500/20 w-14 h-8 rounded-full"></div>
          <div
            className={`${styles.dot} absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition`}></div>
        </div>
      </label>
    </div>
  );
};

export default Switch;
