import {createSlice} from "@reduxjs/toolkit";
import {AuthState} from "./auth.state";
import {
  authenticateUser,
  getAuthenticatedUserInfos,
  logOutUser,
} from "./thunk/login.thunk";
import {registerUser} from "./thunk/register.thunk";

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem("authToken"),
  token: localStorage.getItem("authToken") ?? "",
  userInfos: null,
  status: "idle",
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    reinitializeStatus: state => {
      state.status = "idle";
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authenticateUser.pending, state => {
        state.status = "loading";
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isAuthenticated = true;
        state.token = action.payload;
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(getAuthenticatedUserInfos.pending, state => {
        state.status = "loading";
      })
      .addCase(getAuthenticatedUserInfos.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userInfos = action.payload;
      })
      .addCase(getAuthenticatedUserInfos.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(logOutUser.pending, state => {
        state.status = "loading";
      })
      .addCase(logOutUser.fulfilled, state => {
        state.status = "succeeded";
        state.isAuthenticated = false;
        state.token = "";
      })
      .addCase(logOutUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(registerUser.pending, state => {
        state.status = "loading";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.token = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

const {actions} = authSlice;
export const {reinitializeStatus} = actions;
export default authSlice.reducer;
