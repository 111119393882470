import {FaArrowRight} from "react-icons/fa";
import CustomLink from "../UIs/customLink/CustomLink";
import {useAppDispatch, useAppSelector} from "../../redux/hook";
import {useEffect} from "react";
import {RootState} from "../../redux/store";
import CagnotteComponent from "../UIs/cagnotte/CagnotteComponent";
import {Cagnotte} from "../../models/Cagnotte";
import {fetchThreeLastCagnottes} from "../../redux/features/cagnottes/thunk/fetchThreeLastCagnottes.thunk";

const CagnottesSection = () => {
  const dispatch = useAppDispatch();
  const cagnottes = useAppSelector(
    (state: RootState) => state.cagnottes.cagnottes
  );
  useEffect(() => {
    dispatch(fetchThreeLastCagnottes());
  }, []);

  return (
    <section className="py-10 bg-gray-100">
      <h2 className="text-center py-3 text-2xl">
        <span className="text-corail-100 font-bold text-corail">
          Transformez des Vies :
        </span>{" "}
        Nos Cagnottes à Soutenir
      </h2>
      <div className="max-w-7xl mx-auto flex flex-wrap justify-center">
        {cagnottes.map((cagnotte: Cagnotte) => (
          <CagnotteComponent key={cagnotte.id} cagnotte={cagnotte} />
        ))}
      </div>
      <div className="w-full flex justify-center text-center mt-16">
        <CustomLink
          customClass="flex justify-center items-center gap-2 font-semibold rounded border border-2 border-corail-100 px-5 py-3 text-corail-100 hover:bg-corail-100 hover:text-white"
          link="/cagnotte/cagnottes">
          Voir plus <FaArrowRight />
        </CustomLink>
      </div>
    </section>
  );
};

export default CagnottesSection;
