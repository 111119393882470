import React from "react";
import {FaFacebookF, FaLinkedinIn, FaTwitter, FaWhatsapp} from "react-icons/fa";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

interface ShareButtonsProps {
  shareUrl: string;
}

function ShareButtons({shareUrl}: ShareButtonsProps) {
  return (
    <>
      <p className="mb-2">
        Faites parler la générosité de vos amis sur les réseaux sociaux,
        partagez le lien de la cagnoote
      </p>
      <div className="flex space-x-4 my-4">
        <TwitterShareButton url={shareUrl}>
          <button className="bg-blue-400 hover:bg-blue-500 text-white font-semibold rounded-full h-8 w-8 flex items-center justify-center">
            <FaTwitter />
          </button>
        </TwitterShareButton>

        <FacebookShareButton url={shareUrl}>
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-full h-8 w-8 flex items-center justify-center">
            <FaFacebookF />
          </button>
        </FacebookShareButton>

        <LinkedinShareButton url={shareUrl}>
          <button className="bg-blue-800 hover:bg-blue-900 text-white font-semibold rounded-full h-8 w-8 flex items-center justify-center">
            <FaLinkedinIn />
          </button>
        </LinkedinShareButton>

        <WhatsappShareButton url={shareUrl}>
          <button className="bg-green-400 hover:bg-green-500 text-white font-semibold rounded-full h-8 w-8 flex items-center justify-center">
            <FaWhatsapp />
          </button>
        </WhatsappShareButton>
      </div>
    </>
  );
}

export default ShareButtons;
