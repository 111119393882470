import CagnottesSection from "../components/cagnotteSection/CagnotteSection";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import HeroSide from "../components/heroSide/HeroSide";
import TutoStep from "../components/tutoStep/TutoStep";

const Home = () => {
  return (
    <>
      <div className="mt-16">
        <Header />
      </div>
      <main>
        <TutoStep />
        <CagnottesSection />
        <HeroSide />
        <Footer />
      </main>
    </>
  );
};

export default Home;
