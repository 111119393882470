import {createSlice} from "@reduxjs/toolkit";
import {CagnotteState} from "../states/cagnottes.state";
import {fetchThreeLastCagnottes} from "../thunk/fetchThreeLastCagnottes.thunk";
import {fetchAllCagnottes} from "../thunk/fetchAllCagnottes.thunk";
import {getCagnottesByCategories} from "../thunk/getCagnottesByCategoriesThunk";
import {getCagnotteByTitle} from "../thunk/searchCagnotteByTitle.thunk";
import {fetchUserCagnottes} from "../thunk/fetchUserCagnottes.thunk";
import {closeCagnotte} from "../thunk/closeCagnotte.thunk";
import {openCagnotte} from "../thunk/openCagnotte.thunk";

const initialState: CagnotteState = {
  cagnottes: [],
  pagination: null,
  categoryId: "",
  type: "all",
  status: "idle",
  error: null,
};

const cagnotteSlice = createSlice({
  name: "cagnotte",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchThreeLastCagnottes.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchThreeLastCagnottes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cagnottes = action.payload;
      })
      .addCase(fetchThreeLastCagnottes.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })

      .addCase(fetchAllCagnottes.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchAllCagnottes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cagnottes = action.payload.data;
        state.pagination = action.payload.pagination;
        state.type = "all";
      })
      .addCase(fetchAllCagnottes.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })

      .addCase(getCagnottesByCategories.pending, state => {
        state.status = "loading";
      })
      .addCase(getCagnottesByCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cagnottes = action.payload.data;
        state.pagination = action.payload.pagination;
        state.categoryId = action.payload?.data[0].type_id;
        state.type = "category";
      })
      .addCase(getCagnottesByCategories.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })

      .addCase(getCagnotteByTitle.pending, state => {
        state.status = "loading";
      })
      .addCase(getCagnotteByTitle.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cagnottes = action.payload.data;
        state.pagination = action.payload.pagination;
        state.type = "search";
      })
      .addCase(getCagnotteByTitle.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })

      .addCase(fetchUserCagnottes.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchUserCagnottes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cagnottes = action.payload.data;
        state.pagination = action.payload.pagination;
        state.type = "all";
      })
      .addCase(fetchUserCagnottes.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })

      .addCase(closeCagnotte.pending, state => {
        state.status = "loading";
      })
      .addCase(closeCagnotte.fulfilled, state => {
        state.status = "succeeded";
      })
      .addCase(closeCagnotte.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      })

      .addCase(openCagnotte.pending, state => {
        state.status = "loading";
      })
      .addCase(openCagnotte.fulfilled, state => {
        state.status = "succeeded";
      })
      .addCase(openCagnotte.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default cagnotteSlice.reducer;
