import {createAsyncThunk} from "@reduxjs/toolkit";
import {axios} from "../../../../lib/axios";
import {CloseCagnotteCommand} from "../command/CloseCagnotteCommand";

export const closeCagnotte = createAsyncThunk(
  "cagnotte/closeCagnottes",
  async (payload: CloseCagnotteCommand) => {
    const response = await axios.put(`/auth/jackpots/close`, {
      jackpot: payload.jackpot,
    });

    return response.data;
  }
);
