import React, {useEffect} from "react";
import {fetchAllMessages} from "../../../redux/features/messages/thunk/fetchAllMessages.thunk";
import {useAppDispatch, useAppSelector} from "../../../redux/hook";
import {RootState} from "../../../redux/store";
import {ThreeDots} from "react-loader-spinner";
import {ExtraCagnotteState} from "../../../redux/features/messages/states/extraCagnotte.state";
import ParticipantBlock from "./ParticipantBlock";
import moment from "moment/moment";

interface ParticipantsProps {
    cagnotteId: string;
    country: string;
}
export default function Participants({cagnotteId, country}: ParticipantsProps) {
    const dispatch = useAppDispatch();
    const loadingParticipantsStatus = useAppSelector((state: RootState) => state.cagnottesExtra.status);
    const participants = useAppSelector((state: RootState) => state.cagnottesExtra.extras);
    useEffect(() => {
        dispatch(fetchAllMessages({cagnotteId, country}));
    }, []);
    console.log(participants)
  return (
    <div className="mt-6">
      <h2 className="text-2xl font-semibold">
        Participants <span className="text-xs">({participants.length})</span>
      </h2>
        {loadingParticipantsStatus == "loading" ? (
            <div className="flex align-center justify-center">
                <ThreeDots
                    visible={true}
                    height="24.5"
                    color="#ff7f50"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        ) : (
            <div className="mt-4">
                <div className="bg-gray-100 rounded-md mt-6 py-2 px-4">
                    {participants.length > 0 ? participants.map((participant: ExtraCagnotteState) => (
                        <>
                            <ParticipantBlock
                                key={participant.id}
                                name={participant.is_anonymous ? "Anonyme" : `${participant.firstname} ${participant.lastname}`}
                                published_at={moment(participant.payment_date).fromNow()}
                                comment={participant.comment}
                                amount={participant.hidden_amount ? '' : participant.amount + ' XOF'}
                            />
                        </>
                    )) : (
                        <>
                            <p className="text-center">Aucun commentaire pour le moment.</p>
                        </>
                    )}
                    {/*<div className="text-center">*/}
                    {/*    <button*/}
                    {/*        className="bg-corail-100 text-white py-2 px-4 rounded-lg"*/}
                    {/*    >*/}
                    {/*        Voir plus*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            </div>
        )}
    </div>
  );
}
