import cover from "./../../../images/my-cagnottes.jpg";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hook";
import {RootState} from "../../../redux/store";
import {fetchUserCagnottes} from "../../../redux/features/cagnottes/thunk/fetchUserCagnottes.thunk";
import CagnotteComponent from "../../../components/UIs/cagnotte/CagnotteComponent";
import PaginationComponent from "../../../components/pagination/PaginationComponent";
import {getCagnottesByCategories} from "../../../redux/features/cagnottes/thunk/getCagnottesByCategoriesThunk";
import HeroHeader from "../../../components/heroHeader/HeroHeader";
import BackNavButton from "../../../components/UIs/backHome/BackNavButton";
import {ThreeDots} from "react-loader-spinner";

export default function MyCagnottes() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const cagnottes = useAppSelector((state: RootState) => state.cagnottes);
  const status = useAppSelector((state: RootState) => state.cagnottes.status);
  const dispatch = useAppDispatch();

  const type = useAppSelector((state: RootState) => state.cagnottes.type);

  useEffect(() => {
    dispatch(fetchUserCagnottes({currentPage: 1}));
  }, []);

  const handleChangePage = (page: number) => {
    if (
      cagnottes.type &&
      cagnottes.categoryId &&
      cagnottes.type === "category"
    ) {
      dispatch(
        getCagnottesByCategories({
          categoryId: cagnottes.categoryId,
          currentPage: page,
        })
      );
    } else if (type === "all") {
      dispatch(fetchUserCagnottes({currentPage: page}));
    } else if (type && type === "search") {
      // dispatch(getCagnotteByTitle({title, currentPage: page}));
    }
    setCurrentPage(page);
  };

  return (
    <>
      <div className="mt-16">
        <HeroHeader
          searchInfoPage="/user/my-cagnottes"
          img={cover}
          greatTitle="Explorez / Gérez vos cagnottes ici."
          description="Explorez vos cagnottes au même endroit. Suivez vos contributions ou créez en de nouvelles."
        />
      </div>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-1">
        <BackNavButton />
          {
            status === "loading" ? (
                <div className="flex align-center justify-center">
                  <ThreeDots
                      visible={true}
                      height="100"
                      color="#f95a20"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                  />
                </div>
            ) : (
                <>
                  <h1 className="text-4xl mt-6 text-center">Liste de vos cagnottes</h1>
                  <div className="max-w-7xl mx-auto flex flex-wrap justify-center mt-10">
                    {cagnottes.cagnottes.map(cagnotte => (
                        <CagnotteComponent key={cagnotte.id} cagnotte={cagnotte} />
                    ))}
                  </div>
                </>
            )
          }
        <div>
          <PaginationComponent
            nPages={cagnottes.pagination?.last_page ?? 1}
            currentPage={currentPage}
            setCurrentPage={handleChangePage}
          />
        </div>
      </div>
    </>
  );
}
