import {createAsyncThunk} from "@reduxjs/toolkit";
import {ResetPasswordCommand} from "../command/resetPassword.command";
import {axios} from "../../../../lib/axios";

export const resetPassword = createAsyncThunk(
    "auth/resetPassword",
    async (payload: ResetPasswordCommand) => {
        const response = await axios.put(`reset-password`, payload);
        return response.data;
    }
)