import React, {ReactNode} from "react";

interface RootLayoutProps {
  children: ReactNode;
}

const RootLayout: React.FC<RootLayoutProps> = ({children}: RootLayoutProps) => {
  return <div>{children}</div>;
};

export default RootLayout;
