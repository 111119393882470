import {createSlice} from "@reduxjs/toolkit";
import {UserInfoState} from "./userInfos.state";
import {getUserInfos} from "./thunk/getUserInfos.thunk";
import {updateUserInfos} from "./thunk/updateUserinfos.thunk";

const initialState: UserInfoState = {
  isAuthenticated: !!localStorage.getItem("authToken"),
  token: localStorage.getItem("authToken") ?? "",
  userInfos: null,
  status: "idle",
  error: null,
};

const userInfosSlice = createSlice({
  name: "user-info",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserInfos.pending, state => {
        state.status = "loading";
      })
      .addCase(getUserInfos.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isAuthenticated = true;
        state.userInfos = action.payload;
      })
      .addCase(getUserInfos.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(updateUserInfos.pending, state => {
        state.status = "loading";
      })
      .addCase(updateUserInfos.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isAuthenticated = true;
        state.userInfos = action.payload;
      })
      .addCase(updateUserInfos.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default userInfosSlice.reducer;
