import {v4 as uuidv4} from "uuid";

export const formattedDate = (date: string | undefined) => {
  date ? new Date(date).toISOString().split("T")[0] : "";
};

export const formatDate = (dateString: string | undefined): string => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2); // ajout du 0 pour les mois 1-9
  const day = (`0${date.getDate()}`).slice(-2); // ajout du 0 pour les jours 1-9
  return `${year}-${month}-${day}`;
};

export const calculateRemainingDays = (endDateString: string): number => {
  const endDate = new Date(endDateString);
  const today = new Date();
  const timeDifferenceInMilliseconds = endDate.getTime() - today.getTime();
  return Math.ceil(
      timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24)
  );
};

export function formatDateDayMounthYear(
  dateString: string | undefined
): string {
  if (!dateString) {
    return "";
  }
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = new Date(dateString).toLocaleDateString(
    "fr-FR",
    options
  );
  return formattedDate;
}

export default function formatAmount(amount: number): string {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function convertImageToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

/**
 * Generates a unique ID with an optional prefix.
 *
 * @param {string} [prefix='YOCA-'] - The prefix for the unique ID. Defaults to 'YOCA-'.
 * @return {string} The generated unique ID.
 */
export function generateUniqueId(prefix= 'YOCA-'): string {

  if (!prefix.endsWith('-')) {
    prefix += '-';
  }
  return `${prefix}${uuidv4()}`;
}

export function calculatePercentage (amountPaid: number, totalAmount: number): number {
  if (totalAmount === 0) {
    return 0;
  }
  return (amountPaid / totalAmount) * 100;
};
