import {createAsyncThunk} from "@reduxjs/toolkit";
import {RegisterCommand} from "../command/register.command";
import {axios} from "../../../../lib/axios";

export const registerUser = createAsyncThunk(
  "user/register",
  async (values: RegisterCommand) => {
    const response = await axios.post("/register", values);
    return response.data;
  }
);
