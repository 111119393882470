import {createSlice} from "@reduxjs/toolkit";
import {PaginationState} from "./pagination.state";

const initialState: PaginationState = {
  current_page: 1,
  first_page_url: "",
  from: 1,
  last_page: 2,
  last_page_url: "",
  links: [
    {
      url: null,
      label: "1",
      active: false,
    },
  ],
  next_page_url: "",
  path: "",
  per_page: 3,
  prev_page_url: null,
  to: 3,
  total: 6,
  status: "idle",
  error: null,
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {},
  // extraReducers: builder => {
  // console.log(builder);
  // },
});

export default paginationSlice.reducer;
