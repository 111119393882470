import Axios from "axios";
// import {API_URL} from "../config";

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://api.yocagnotte.com',
  timeout: 10000,
});

axios.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("authToken");
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
    config => {
      const authToken = localStorage.getItem("authToken");
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
        error => {
      return Promise.reject(error)
    }
);
