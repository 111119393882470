import bgImage from "./../../../images/reset-pawword.jpg";
import BackNavButton from "../../../components/UIs/backHome/BackNavButton";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useAppDispatch, useAppSelector} from "../../../redux/hook";
import {RootState} from "../../../redux/store";
import {mailReset} from "../../../redux/features/mail-reset/thunk/mailReset.thunk";
import React, {useEffect} from "react";
import Swal from "sweetalert2";
import {ThreeDots} from "react-loader-spinner";

export default function MailUser() {
  const dispatch = useAppDispatch();
  const mailStatus = useAppSelector(
    (state: RootState) => state.mailReset.status
  );
  const formik = useFormik({
    initialValues: {
      account: "",
    },
    validationSchema: Yup.object({
      account: Yup.string()
        .email("L'adresse e-mail n'est pas valide")
        .required("Ce champ est obligatoire")
        .test("email", "L'adresse e-mail n'est pas valide", function (value) {
          if (!value) return true;
          return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value);
        }),
    }),
    onSubmit: async (payload: {account: string}) => {
      dispatch(mailReset(payload));
    },
  });

  useEffect(() => {
    if (mailStatus === "failed") {
      Swal.fire({
        title: "Erreur",
        iconColor: "#d33",
        text: "Une erreur est survenue, veuillez vérifier l'adresse entrée.",
        icon: "error",
        confirmButtonColor: "#FF7F50",
      });
    } else if (mailStatus === "succeeded") {
      Swal.fire({
        title: "Réinitialisation du mot de passe",
        iconColor: "#34d399",
        text: "Un mail vous a été envoyé à l'adresse mentionnée. Veuillez suivre ses instructions.",
        icon: "success",
        confirmButtonColor: "#FF7F50",
      });
    }
  }, [mailStatus]);

  return (
    <>
      <div
        className="w-full md:w-2/3 bg-cover"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
        }}></div>
      <div className="w-full flex-col flex justify-center md:w-1/3 p-4 md:p-12 overflow-y-auto max-h-screen">
        <BackNavButton />
        {mailStatus === 'succeeded' ? (
            <>
              <h1 className="font-bold text-2xl text-center mb-2">
                Réinitialisation du mot de passe
              </h1>
              <p className="small mb-6">Un mail vous a été envoyé, veuillez suivre ses instructions.</p>
              <div className="flex justify-center">
                <svg width="100" height="100" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="10" fill="#d4edda"/>
                  <path d="M9.29 14.29L6 11l-1.41 1.41L9.29 17.12l9.12-9.12L16.71 7l-7.42 7.42z" fill="#28a745"/>
                </svg>
              </div>
            </>
        ) : (
            <>
              <h1 className="font-bold text-4xl mb-2">Mot de passe oublié</h1>
              <p className="small mb-6">
                Entrez votre adresse mail, un email vous sera envoyé pour
                réinitialiser votre mot de passe
              </p>
              <form className="w-full" onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                  <label
                      htmlFor={`account`}
                      className="block text-sm font-medium text-gray-600">
                    Email
                  </label>
                  <input
                      className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
                      type="email"
                      id={`account`}
                      required
                      name={`account`}
                      autoComplete="email"
                      placeholder="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.account}
                  />
                  {formik.touched.account && formik.errors.account && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.account}
                      </div>
                  )}
                </div>

                <button
                    type="submit"
                    className={`${
                        !formik.isValid
                            ? "w-full bg-corail-100 hover:bg-corail-500 text-white p-2 rounded-md opacity-50 cursor-not-allowed"
                            : "w-full bg-corail-100 hover:bg-corail-500 text-white p-2 rounded-md"
                    }`}
                    disabled={!formik.isValid}>
                  {mailStatus === "loading" ? (
                      <div className="flex align-center justify-center">
                        <ThreeDots
                            visible={true}
                            height="24.5"
                            color="#fff"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                      </div>
                  ) : (
                      "Envoyer"
                  )}
                </button>
              </form>
            </>
          )}
      </div>
    </>
  );
}
