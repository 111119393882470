import {createAsyncThunk} from "@reduxjs/toolkit";
import {axios} from "../../../../lib/axios";
import {UpdateUserInfosCommand} from "../command/updateUserInfos.command";

export const updateUserInfos = createAsyncThunk(
  "updateUserInfos/userInfos",
  async (payload: UpdateUserInfosCommand) => {
    await axios.put("auth/infos", payload);
    return payload;
  }
);
