import {createAsyncThunk} from "@reduxjs/toolkit";
import {axios} from "../../../../lib/axios";
import {CreateCagnotteCommand} from "../command/CreateCagnotteCommand";

export const createCagnotte = createAsyncThunk(
  "cagnotte/createCagnottes",
  async (payload: CreateCagnotteCommand) => {
    const response = await axios.post(`auth/jackpots/create`, payload);
    return response.data;
  }
);
