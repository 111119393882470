import {useEffect, useState} from "react";
import HeroHeader from "../../../components/heroHeader/HeroHeader";
import {useAppDispatch, useAppSelector} from "../../../redux/hook";
import {RootState} from "../../../redux/store";
import cover from "./../../../images/solidarity.png";
import {fetchAllCagnottes} from "../../../redux/features/cagnottes/thunk/fetchAllCagnottes.thunk";
import CagnotteComponent from "../../../components/UIs/cagnotte/CagnotteComponent";
import PaginationComponent from "../../../components/pagination/PaginationComponent";
// import BackNavButton from "../../../components/UIs/backHome/BackNavButton";
import {useSearchParams} from "react-router-dom";
import {getCagnotteByTitle} from "../../../redux/features/cagnottes/thunk/searchCagnotteByTitle.thunk";
import {getCagnottesByCategories} from "../../../redux/features/cagnottes/thunk/getCagnottesByCategoriesThunk";
import {ThreeDots} from "react-loader-spinner";
import loupe from "../../../images/loupe.svg";

const Cagnottes = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");
  const categoryId = useAppSelector(
    (state: RootState) => state.cagnottes.categoryId
  );
  const type = useAppSelector((state: RootState) => state.cagnottes.type);

  useEffect(() => {
    if (query !== null) {
      dispatch(getCagnotteByTitle({title: query, currentPage: 1}));
    }
  }, [query]);

  const dispatch = useAppDispatch();

  const cagnottes = useAppSelector(
    (state: RootState) => state.cagnottes.cagnottes
  );
  const status = useAppSelector((state: RootState) => state.cagnottes.status);
  const infoPagination = useAppSelector(
    (state: RootState) => state.cagnottes.pagination
  );

  const handleChangePage = (page: number) => {
    if (type && categoryId && type === "category") {
      dispatch(getCagnottesByCategories({categoryId, currentPage: page}));
    } else if (type === "all") {
      dispatch(fetchAllCagnottes(page));
    } else if (type && type === "search") {
      // dispatch(getCagnotteByTitle({title, currentPage: page}));
    }
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [type]);

  useEffect(() => {
    dispatch(fetchAllCagnottes(currentPage));
  }, []);

  return (
    <>
      <div className="mt-16">
        <HeroHeader
          searchInfoPage="/cagnotte/cagnottes"
          img={cover}
          greatTitle="Identifiez la cause qui résonne le mieux en vous."
          description="Vous souhaitez soutenir une cause qui vous tient à cœur ? Explorez les projets ici et contribuez à leur cagnotte solidaire."
        />
      </div>
      <div className="max-w-screen-xl text-center mx-auto px-4 sm:px-6 lg:px-1">
        {/* <BackNavButton /> */}
        {status === "loading" ? (
          <div className="flex align-center justify-center">
            <ThreeDots
              visible={true}
              height="100"
              color="#f95a20"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <>
            {cagnottes.length > 0 ? (
              <>
                <div className="max-w-7xl mx-auto flex flex-wrap justify-center">
                  {cagnottes.map(cagnotte => (
                    <CagnotteComponent key={cagnotte.id} cagnotte={cagnotte} />
                  ))}
                </div>
                <div>
                  <PaginationComponent
                    nPages={infoPagination?.last_page ?? 1}
                    currentPage={currentPage}
                    setCurrentPage={handleChangePage}
                  />
                </div>
              </>
            ) : (
              <div className="mt-12 max-w-md mx-auto bg-white rounded-lg overflow-hidden">
                <div className="p-4">
                  <div className="flex justify-center">
                    <img
                      src={loupe}
                      alt="Image svg d'une loupe"
                      className="object-cover"
                      width={150}
                    />
                  </div>
                  <p className="text-center text-corail-100 font-bold mt-4">
                    Nous n&apos;avons trouvé aucune cagnotte portant le nom
                    recherché
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Cagnottes;
