import {useState} from "react";
import {FaEye, FaEyeSlash} from "react-icons/fa";

interface PasswordInputProps {
  id: string;
  name: string;
  label: string;
  value: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  touched?: boolean;
  error?: string;
}

const PasswordInput = ({
  id,
  name,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  touched,
  error,
}: PasswordInputProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="mb-4 relative">
      <label htmlFor={id} className="block text-sm font-medium text-gray-600">
        {label}
      </label>
      <input
        id={id}
        name={name}
        autoComplete="current-password"
        className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100 pr-10"
        type={passwordVisible ? "text" : "password"}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <button
        type="button"
        className="absolute right-5 top-9 opacity-50"
        onClick={togglePasswordVisibility}>
        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
      </button>
      {touched && error && <div className="text-red-500">{error}</div>}
    </div>
  );
};

export default PasswordInput;
