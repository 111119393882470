import {useAppDispatch, useAppSelector} from "../../redux/hook";
import {RootState} from "../../redux/store";
import {useFormik} from "formik";
import * as Yup from "yup";
import {UpdateUserInfosCommand} from "../../redux/features/user-infos/command/updateUserInfos.command";
import Swal from "sweetalert2";
import {updateUserInfos} from "../../redux/features/user-infos/thunk/updateUserinfos.thunk";
import {useEffect} from "react";
import {resetStatus} from "../../redux/features/update-user-infos/updateUserInfosSlice";
import {getUserInfos} from "../../redux/features/user-infos/thunk/getUserInfos.thunk";
import Card from "../card/Card";
import {ThreeDots} from "react-loader-spinner";

const UserProfile = () => {
    const dispatch = useAppDispatch();
    const userInfos = useAppSelector(
        (state: RootState) => state.userInfos.userInfos
    );
    const userInfosStatus = useAppSelector((state: RootState) => state.userInfos.status);
    const updateUserInfosStatus = useAppSelector(
        (state: RootState) => state.updateUserInfos.status
    );
    const formik = useFormik({
        initialValues: {
            lastname: "",
            firstname: "",
            birthday: "",
            username: "",
            city: "",
            occupation: "",
            gender: "",
        },
        validationSchema: Yup.object({
            lastname: Yup.string()
                .required("Ce champ est obligatoire")
                .min(2, "Veuillez entrer un nom correct"),
            firstname: Yup.string()
                .required("Ce champ est obligatoire")
                .min(2, "Veuillez entrer un nom correct"),
            birthday: Yup.date()
                .max(new Date(), "La date de naissance ne peut pas être dans le futur")
                .required("Ce champ est obligatoire")
                .test("is-adult", "Vous devez avoir au moins 18 ans", function (value) {
                    const cutoffDate = new Date();
                    cutoffDate.setFullYear(cutoffDate.getFullYear() - 18);
                    return new Date(value) <= cutoffDate;
                }),
            username: Yup.string().required("Ce champ est obligatoire"),
            city: Yup.string()
                .required("Ce champ est obligatoire")
                .min(2, "Veuillez entrer un nom de pays correct"),
            occupation: Yup.string()
                .required("Ce champ est obligatoire")
                .min(2, "Veuillez entrer correctement votre profession"),
            gender: Yup.string().required("Ce champ est obligatoire"),
        }),
        onSubmit: values => {
            updateUserInfosFunction(values);
        },
    });

    useEffect(() => {
        if (userInfos) {
            formik.setValues({
                ...formik.values,
                lastname: userInfos?.lastname ?? "",
                firstname: userInfos?.firstname ?? "",
                birthday: userInfos?.birthday ?? "",
                username: userInfos?.username ?? "",
                city: userInfos?.city ?? "",
                occupation: userInfos?.occupation ?? "",
                gender: userInfos?.gender ?? "",
            });
        }
    }, [userInfos]);

    const updateUserInfosFunction = (values: UpdateUserInfosCommand) => {
        Swal.fire({
            title: "Modification de vos informations",
            text: "Êtes vous sûr de vouloir effectuer cette action ?",
            icon: "warning",
            iconColor: "#FF7F50",
            showCancelButton: true,
            confirmButtonColor: "#FF7F50",
            cancelButtonColor: "#d33",
            cancelButtonText: "Annuler",
            confirmButtonText: "Oui, modifier",
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(updateUserInfos(values));
            }
        });
    };

    useEffect(() => {
        if (updateUserInfosStatus === "failed") {
            Swal.fire({
                title: "Erreur",
                iconColor: "#d33",
                text: "Une erreur est survenue, veuillez reessayer.",
                icon: "error",
                confirmButtonColor: "#FF7F50",
            });
        } else if (updateUserInfosStatus === "succeeded") {
            Swal.fire({
                title: "Modification de vos informations",
                iconColor: "#34d399",
                text: "Vos informations ont été modifiés avec succès.",
                icon: "success",
                confirmButtonColor: "#FF7F50",
            }).then(() => {
                dispatch(resetStatus());
            });
        }
    }, [updateUserInfosStatus]);

    useEffect(() => {
        dispatch(getUserInfos());
    }, []);

    return (
        <>
            <h1 className={`text-5xl`}>Mes informations</h1>
            <p className="mb-4">
                Vous pouvez modifier vos informations en modifiant puis validant le
                formulaire ci-dessous
            </p>
            <Card>
                {userInfosStatus === "loading" ?
                    (
                        <>
                            <div className="flex align-center justify-center">
                                <ThreeDots
                                    visible={true}
                                    height="24.5"
                                    color="#f95a20"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>
                        </>
                    ) : (
                        <form className="w-full" onSubmit={formik.handleSubmit}>
                            <div className="flex gap-4">
                                <div className="mb-4 lg:w-1/2">
                                    <label
                                        htmlFor={`firstname`}
                                        className="block text-sm font-medium text-gray-600">
                                        Nom
                                    </label>
                                    <input
                                        className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
                                        type="text"
                                        id={`firstname`}
                                        name={`firstname`}
                                        placeholder="Nom"
                                        value={formik.values.firstname}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.firstname && formik.errors.firstname && (
                                        <div className="text-red-500">
                                            {formik.errors.firstname}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-4 lg:w-1/2">
                                    <label
                                        htmlFor="lastname"
                                        className="block text-sm font-medium text-gray-600">
                                        Prénoms
                                    </label>
                                    <input
                                        className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
                                        type="text"
                                        id={`lastname`}
                                        name={`lastname`}
                                        placeholder="Prénoms"
                                        value={formik.values.lastname}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.lastname && formik.errors.lastname && (
                                        <div className="text-red-500">{formik.errors.lastname}</div>
                                    )}
                                </div>
                            </div>
                            <div className="mb-4">
                                <label
                                    htmlFor={`birthday`}
                                    className="block text-sm font-medium text-gray-600">
                                    Date de naissance
                                </label>
                                <input
                                    className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
                                    type="date"
                                    id={`birthday`}
                                    name={`birthday`}
                                    placeholder="Date de naissance"
                                    value={formik.values.birthday}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.birthday && formik.errors.birthday && (
                                    <div className="text-red-500">{formik.errors.birthday}</div>
                                )}
                            </div>
                            <div className="flex gap-4">
                                <div className="mb-4 lg:w-1/2">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium text-gray-600">
                                        Email
                                    </label>
                                    <input
                                        className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
                                        type="text"
                                        id={`email`}
                                        name={`email`}
                                        placeholder="Adresse email"
                                        value={userInfos?.email}
                                        disabled
                                    />
                                </div>
                                <div className="mb-4 lg:w-1/2">
                                    <label
                                        htmlFor={`phone`}
                                        className="block text-sm font-medium text-gray-600">
                                        Téléphone
                                    </label>
                                    <input
                                        className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
                                        type="tel"
                                        id={`phone`}
                                        placeholder="Téléphone"
                                        value={userInfos?.phone}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="mb-4 flex gap-3 items-center">
                                <p>Vous êtes</p>
                                <input
                                    id="male"
                                    type="radio"
                                    name="gender"
                                    value="M"
                                    checked={formik.values.gender === "M"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <label htmlFor="male" className="text-corail-500">
                                    Homme
                                </label>

                                <input
                                    id="female"
                                    type="radio"
                                    name="gender"
                                    value="F"
                                    checked={formik.values.gender === "F"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <label htmlFor="female" className="text-corail-500">
                                    Femme
                                </label>

                                <input
                                    id="other"
                                    type="radio"
                                    name="gender"
                                    value="O"
                                    checked={formik.values.gender === "O"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <label htmlFor="other" className="text-corail-500">
                                    Autre
                                </label>
                                {formik.touched.gender && formik.errors.gender && (
                                    <div className="text-red-500">{formik.errors.gender}</div>
                                )}
                            </div>
                            <div className="flex gap-4">
                                <div className="mb-4 lg:w-1/2">
                                    <label
                                        htmlFor={`username`}
                                        className="block text-sm font-medium text-gray-600">
                                        Pseudo
                                    </label>
                                    <input
                                        className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
                                        type="text"
                                        id={`username`}
                                        name={`username`}
                                        placeholder="Pseudonyme"
                                        value={formik.values.username}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.username && formik.errors.username && (
                                        <div className="text-red-500">{formik.errors.username}</div>
                                    )}
                                </div>
                                <div className="mb-4 lg:w-1/2">
                                    <label
                                        htmlFor={`occupation`}
                                        className="block text-sm font-medium text-gray-600">
                                        Profession
                                    </label>
                                    <input
                                        className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
                                        type="text"
                                        id={`occupation`}
                                        name={`occupation`}
                                        placeholder="Profession"
                                        value={formik.values.occupation}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.occupation && formik.errors.occupation && (
                                        <div className="text-red-500">
                                            {formik.errors.occupation}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mb-4">
                                <label
                                    htmlFor={`city`}
                                    className="block text-sm font-medium text-gray-600">
                                    Ville
                                </label>
                                <input
                                    className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
                                    type="tel"
                                    id={`city`}
                                    name={`city`}
                                    placeholder="Téléphone"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.city && formik.errors.city && (
                                    <div className="text-red-500">{formik.errors.city}</div>
                                )}
                            </div>
                            <button
                                type="submit"
                                className={`w-full bg-corail-100 hover:bg-corail-500 text-white p-2 rounded-md
                    ${
                                    formik.isValid
                                        ? "w-full bg-corail-100 hover:bg-corail-500 text-white p-2 rounded-md"
                                        : "opacity-50 cursor-not-allowed"
                                }
                  `}
                                disabled={!formik.isValid}>
                                {updateUserInfosStatus === "loading" ? (
                                    <div className="flex align-center justify-center">
                                        <ThreeDots
                                            visible={true}
                                            height="24.5"
                                            color="#fff"
                                            radius="9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                    </div>
                                ) : (
                                    "Modifier mes informations"
                                )}
                            </button>
                        </form>
                    )
                }
            </Card>
        </>
    );
}

export default UserProfile;
