import {createSlice} from "@reduxjs/toolkit";
import ResetPasswordState from "./resetPassword.state";
import {resetPassword} from "./thunk/resetPassword.thunk";

const initialState: ResetPasswordState = {
    resetPasswordResponse: {
        code: null,
        status: "",
        data: "",
    },
    status: "idle",
    error: null,
}

const resetPasswordSlice = createSlice({
    name: "resetPassword",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(resetPassword.pending, state => {
                state.status = "loading";
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.resetPasswordResponse = action.payload;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    }
});

export default resetPasswordSlice.reducer;