import {ReactNode} from "react";
import {useAppDispatch, useAppSelector} from "../../redux/hook";
import {RootState} from "../../redux/store";
import {MdClose} from "react-icons/md";
import {closeModal} from "../../redux/features/modal/ModalSlice";

interface ModalProps {
    modalTitle: string;
    children: ReactNode;
}
const Modal = ({ modalTitle, children }: ModalProps) => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state: RootState) => state.modal.isOpen);

    const handleClose = () => {
        dispatch(closeModal());
    }

    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 overflow-y-auto z-50">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" onClick={() => handleClose()}>
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 border-b">
                        <h3 className="flex items-center justify-between text-lg leading-6 font-medium text-gray-900" id="modal-title">
                            {modalTitle}
                            <MdClose
                                className="text-xl cursor-pointer hover:bg-gray-200 rounded-full"
                                onClick={() => handleClose()}
                            />
                        </h3>
                    </div>
                    <div className="bg-white p-6 sm:p-6">
                        {children}
                    </div>
                    {/*<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">*/}
                    {/*    <button type="button" onClick={handleClose} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm">*/}
                    {/*        Annuler*/}
                    {/*    </button>*/}
                    {/*    <button type="button" onClick={handleClose} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">*/}
                    {/*        Modifier*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}

export default Modal;
