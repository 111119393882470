import {createAsyncThunk} from "@reduxjs/toolkit";
import {axios} from "../../../../lib/axios";
import {GetPaymentUrlCommand} from "../command/GetPaymentUrlCommand";

export const getPaymentUrl = createAsyncThunk(
    "getPaymentUrl",
    async (payload: GetPaymentUrlCommand) => {
        const response = await axios.post("https://api-checkout.cinetpay.com/v2/payment", payload)
        return response.data
    }
);

