import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import {Outlet} from "react-router-dom";

const CagnotteLayout = () => {
  return (
    <>
      <div className="h-screen">
        <Navbar />
        <main>
          <Outlet />
        </main>
        <div className="mt-6">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CagnotteLayout;
