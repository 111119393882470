import {useFormik} from "formik";
import * as Yup from "yup";
import CustomLink from "../../../components/UIs/customLink/CustomLink";
import BackNavButton from "../../../components/UIs/backHome/BackNavButton";
import {useAppDispatch, useAppSelector} from "../../../redux/hook";
import {authenticateUser} from "../../../redux/features/auth/thunk/login.thunk";
import {RootState} from "../../../redux/store";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import bgImage from "./../../../images/sign-up-img-2.jpeg";
import Swal from "sweetalert2";
import SubmitBtnWithLoader from "../../../components/UIs/submitBtnWithLoader/SubmitBtnWithLoader";
import {reinitializeStatus} from "../../../redux/features/auth/authSlice";
import PasswordInput from "../../../components/UIs/passwordInput/PasswordInput";

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const statut = useAppSelector((state: RootState) => state.auth.status);

  const formik = useFormik({
    initialValues: {
      account: "",
      password: "",
    },
    validationSchema: Yup.object({
      account: Yup.string()
        .email("L'adresse email est invalide")
        .required("L'adresse email est obligatoire"),
      password: Yup.string().required("Le mot de passe est nécéssaire"),
    }),
    onSubmit: values => {
      dispatch(authenticateUser(values));
    },
  });

  useEffect(() => {
    if (statut === "succeeded") {
      navigate("/");
    } else if (statut === "failed") {
      Swal.fire({
        title: "Données invalide",
        iconColor: "#d33",
        text: "Les informations entrées ne correspondent à aucun enregistrement dans notre base de données.",
        icon: "error",
        confirmButtonColor: "#FF7F50",
      }).then(() => {
        dispatch(reinitializeStatus());
      });
    } else {
      return;
    }
  }, [statut]);

  return (
    <>
      <div
        className="w-full md:w-2/3 bg-cover"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
        }}></div>
      <div className="w-full flex-col flex justify-center md:w-1/3 p-4 md:p-12 overflow-y-auto max-h-screen">
        <BackNavButton />
        <h1 className="font-bold text-4xl mb-6">Se connecter</h1>
        <form className="w-full" onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor={`account`}
              className="block text-sm font-medium text-gray-600">
              Email
            </label>
            <input
              className="mt-1 p-2 w-full border rounded-md focus:outline-corail-100"
              type="email"
              id={`account`}
              required
              name={`account`}
              autoComplete="email"
              placeholder="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.account}
            />
            {formik.touched.account && formik.errors.account && (
              <div className="text-red-500 text-sm">
                {formik.errors.account}
              </div>
            )}
          </div>

          <PasswordInput
              label="Ancien mot de passe"
              id={`password`}
              name={`password`}
              placeholder="Mot de passe"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.password}
              error={formik.errors.password}
          />
          <SubmitBtnWithLoader label="Se connecter" status={statut} />
        </form>

        <div className="w-full text-sm flex flex-col items-center mt-4 md:flex-row md:justify-end">
          <a
            href="/auth/mail-reset-password"
            className="text-corail-500 hover:underline font-semibold">
            Mot de passe oublié ?
          </a>
        </div>

        <div className="w-full mt-4">
          <CustomLink
            link="/auth/register"
            customClass="block text-corail-100 text-center border-corail-100 border hover:bg-corail-100 hover:text-white text-corail rounded py-2 px-4 font-medium">
            S&apos;inscrire
          </CustomLink>
        </div>
      </div>
    </>
  );
};

export default Login;
