import React, { ReactNode } from "react";

interface CardProps {
    children: ReactNode;
}

const Card : React.FC<CardProps> = ({ children }) => {
    return (
        <div className="rounded-lg p-6 border-2">
            {children}
        </div>
    );
}

export default Card;

