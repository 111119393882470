import { createAsyncThunk } from "@reduxjs/toolkit";
import {axios} from "../../../../lib/axios";
import {InitializePaymentCommand} from "../command/InitializePaymentCommand";


export const initializePayment = createAsyncThunk(
    "payCagnotte",
    async (payload: InitializePaymentCommand) => {
        try {
            const response = await axios.post("/cashs/in", payload);
            return response.data;
        } catch (error) {
            // console.log(error);
        }
    }
);
