import img from "./../../images/hero-side-illustration.jpeg";
import React from "react";
import CustomLink from "../UIs/customLink/CustomLink";

const HeroSide = () => {
  return (
    <section className="py-10">
      <div className="max-w-7xl mx-auto flex flex-col-reverse lg:flex-row items-center shadow mt-10">
        <div className="w-full lg:w-1/2 p-10">
          <h2 className="text-3xl font-bold tracking-tight text-corail sm:text-4xl mb-7">
            Transformez chaque moment en occasion spéciale avec YoCagnotte
          </h2>
          <p className="text-lg mb-4">
            Chez YoCagnotte, nous comprenons que chaque moment compte,
            qu&apos;il s&apos;agisse de célébrer un mariage, de soutenir une
            cause qui vous tient à cœur, ou simplement de réunir des fonds pour
            un projet de rêve. C&apos;est pourquoi nous avons créé une
            plateforme simple et sécurisée qui vous accompagne à chaque étape.
            Avec YoCagnotte, collecter de l&apos;argent n&apos;a jamais été
            aussi facile et transparent.
            {/*Découvrez comment nous pouvons vous aider à faire de chaque occasion une réussite.*/}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
            <CustomLink customClass="bg-corail text-white hover:bg-corail-500 text-corail rounded py-2 px-4 text-xl">
              Créer une cagnotte
            </CustomLink>
          </div>
        </div>

        <div className="w-full lg:w-1/2">
          <img
            src={img}
            alt="Description de l'image"
            className="w-full object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSide;
