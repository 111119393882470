import {createSlice} from "@reduxjs/toolkit";
import {createCagnotte} from "../thunk/createCagnotte.thunk";
import {CreateCagnotteState} from "../states/createCagnotte.state";

const initialState: CreateCagnotteState = {
  cagnotte: null,
  status: "idle",
  error: null,
};

const createCagnotteSlice = createSlice({
  name: "createCagnotte",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createCagnotte.pending, state => {
        state.status = "loading";
      })
      .addCase(createCagnotte.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cagnotte = action.payload;
      })
      .addCase(createCagnotte.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default createCagnotteSlice.reducer;
