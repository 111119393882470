import {useNavigate, useParams} from "react-router-dom";
import BackNavButton from "../../../components/UIs/backHome/BackNavButton";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hook";
import {RootState} from "../../../redux/store";
import {fetchCagnotteDetails} from "../../../redux/features/cagnottes/thunk/fetchCagnotteDetails.thunk";
import Switch from "../../../components/UIs/switch/switch/switch";
import {useFormik} from "formik";
import * as Yup from "yup";
import {initializePayment} from "../../../redux/features/pay/thunk/initializePayment.thunk";
import SubmitBtnWithLoader from "../../../components/UIs/submitBtnWithLoader/SubmitBtnWithLoader";
import {InitializePaymentCommand} from "../../../redux/features/pay/command/InitializePaymentCommand";
import {getPaymentUrl} from "../../../redux/features/pay/thunk/getPaymentUrl.thunk";
import {GetPaymentUrlCommand} from "../../../redux/features/pay/command/GetPaymentUrlCommand";
import Swal from "sweetalert2";
// import FormattedTextField from "../../../components/formatted-text-field/FormattedTextField";

const CagnottePay = () => {
  const navigate = useNavigate();
  const params = useParams<string>();
  const dispatch = useAppDispatch();
  const {initializePaymentStatus, data} = useAppSelector((state: RootState) => state.payCagnotte);
  const cinetpayPaymentUrlStatus = useAppSelector((state: RootState) => state.cinetpayPayment.status);
  const cinetpayPaymentResponse = useAppSelector((state: RootState) => state.cinetpayPayment.data);
  const cagnotteDetails = useAppSelector((state: RootState) => state.cagnotteDetails.details);
  const min_amount = cagnotteDetails?.unit_amount ?? null;
  const [formValues, setFormValues] = useState<InitializePaymentCommand>({
    jackpot: "",
    country: "",
    amount: 0,
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    phone: "",
    comment: "",
    is_anonymous: false,
    hidden_amount: false,
  });

  useEffect(() => {
    if (params.id) {
      dispatch(fetchCagnotteDetails({id: params.id}));
    }
  }, [dispatch, params]);

  const formik = useFormik({
    initialValues: {
      amount: "",
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      comment: "",
      phone: "",
      is_anonymous: false,
      hidden_amount: false,
    },
    validationSchema: Yup.object({
      amount: Yup.number()
          .required("Vous devez entrer le montant de votre participation")
          .min(Number(min_amount), `Le montant doit être supérieur ou égal à ${min_amount} XOF`),
      phone: Yup.string().required("Veuillez entrer votre numéro de téléphone"),
      lastname: Yup.string().required("Ce champ est obligatoire"),
      comment: Yup.string(),
      firstname: Yup.string().required("Ce champ est obligatoire"),
      email: Yup.string()
          .email("L'adresse e-mail n'est pas valide")
          .required("Ce champ est obligatoire"),
      is_anonymous: Yup.boolean(),
      hidden_amount: Yup.boolean(),
    }),
    onSubmit: async values => {
      const payload = {
        jackpot: cagnotteDetails?.id,
        country: "99b93824-8625-4546-98eb-97eebc37b172", // Côte d'Ivoire
        amount: Number(values.amount),
        firstname: values.firstname,
        lastname: values.lastname,
        username: values.username,
        email: values.email,
        phone: values.phone,
        comment: values.comment,
        is_anonymous: values.is_anonymous,
        hidden_amount: values.hidden_amount,
      };
      setFormValues(prevState => ({
        ...prevState,
        ...payload
      }));
      dispatch(initializePayment(payload));
    },
  });

  useEffect(() => {
    if (initializePaymentStatus === "succeeded" && data) {
      const payload: GetPaymentUrlCommand = {
        apikey: process.env.REACT_APP_API_KEY as string,
        site_id: Number(process.env.REACT_APP_SITE_ID),
        transaction_id: data.id,
        amount: data.amount,
        currency: data.devise ?? "XOF",
        description: "Je participe à la cagnotte : " + cagnotteDetails?.title,
        notify_url: process.env.REACT_APP_API_BASE_URL + "/cashs/in/notify",
        return_url: process.env.REACT_APP_SITE_BASE_URL + `/cagnotte/details/${cagnotteDetails?.id}`,
        redirect_url: process.env.REACT_APP_SITE_BASE_URL + `/cagnotte/details/${cagnotteDetails?.id}`,
        channels: "ALL",
        metadata: JSON.stringify(formValues),
        lang: "FR",
      };
      dispatch(getPaymentUrl(payload));
    }
  }, [initializePaymentStatus]);

  useEffect(() => {
    if (cinetpayPaymentUrlStatus === "succeeded" && cinetpayPaymentResponse.payment_url) {
        window.location.replace(cinetpayPaymentResponse.payment_url);
    } else if (cinetpayPaymentUrlStatus === "failed") {
      Swal.fire({
        title: "Erreur",
        iconColor: "#d33",
        text: "Une erreur est survenue, veuillez reessayer svp.",
        icon: "error",
        confirmButtonColor: "#FF7F50",
      }).then(() => {
        navigate("/cagnotte/details/" + cagnotteDetails?.id);
      });
    }
  }, [cinetpayPaymentUrlStatus]);

  return (
    <div className="w-full md:w-2/5 md:p-0 p-4 mx-auto">
      <div className="mt-28">
        <BackNavButton />
      </div>
      <h1 className="text-center text-2xl text-corail-100 font-bold mb-6">
        {cagnotteDetails?.title}
      </h1>
      <div className="border rounded-md p-6">
        <h2 className="font-semibold text-xl text-gray-800 ">
          Veuillez remplir le formulaire
        </h2>
        <div className="mt-6">
          <form encType="multipart/form-data" onSubmit={formik.handleSubmit}>
            <div className="mb-6">
              <label
                htmlFor="amount"
                className="block mb-2 text-sm font-medium text-gray-900">
                Montant <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                id="amount"
                name="amount"
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                placeholder="20000"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
              />
              {formik.touched.amount && formik.errors.amount ? (
                <div className="text-red-500">{formik.errors.amount}</div>
              ) : null}
            </div>

            <div className="mb-6">
              <label
                  htmlFor="firstname"
                  className="block mb-2 text-sm font-medium text-gray-900">
                Nom <span className="text-red-500">*</span>
              </label>
              <input
                  type="text"
                  id="firstname"
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstname}
              />
              {formik.touched.firstname && formik.errors.firstname ? (
                <div className="text-red-500">{formik.errors.firstname}</div>
              ) : null}
            </div>

            <div className="mb-6">
              <label
                  htmlFor="lastname"
                  className="block mb-2 text-sm font-medium text-gray-900">
                Prénoms <span className="text-red-500">*</span>
              </label>
              <input
                  type="text"
                  id="lastname"
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastname}
              />
              {formik.touched.lastname && formik.errors.lastname ? (
                <div className="text-red-500">{formik.errors.lastname}</div>
              ) : null}
            </div>

            <div className="mb-6">
              <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900">
                Email <span className="text-red-500">*</span>
              </label>
              <input
                  type="email"
                  id="email"
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="mb-6">
              <label
                  htmlFor="phone"
                  className="block mb-2 text-sm font-medium text-gray-900">
                Téléphone <span className="text-red-500">*</span>
              </label>
              <input
                  type="tel"
                  id="phone"
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
              />
              {formik.touched.phone && formik.errors.phone ? (
                  <div className="text-red-500">{formik.errors.phone}</div>
              ) : null}
            </div>

            <div className="mb-6">
              <label
                htmlFor="comment"
                className="block mb-2 text-sm font-medium text-gray-900">
                Ajouter un commentaire ?
              </label>
              <textarea
                  name="comment"
                  id="comment"
                  cols={30}
                  rows={10}
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-none focus:ring-corail-500 focus:outline-none focus:ring-2 block w-full p-2.5"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.comment}></textarea>
              {/*<FormattedTextField />*/}
              {/*<Field*/}
              {/*    name="comment"*/}
              {/*    component={({field}: any) => (*/}
              {/*        <FormattedTextField*/}
              {/*            field={field.name}*/}
              {/*            form={{ values: formik.values, setFieldValue: formik.setFieldValue }}*/}
              {/*        />*/}
              {/*    )}*/}
              {/*/>*/}
              {formik.touched.comment && formik.errors.comment ? (
                <div className="text-red-500">{formik.errors.comment}</div>
              ) : null}
            </div>

            <div className="mb-6">
              <Switch
                title="Rendre ma participation anonyme"
                description="Dans les commentaires, votre nom ne sera pas affiché"
                name="is_anonymous"
                field={formik.getFieldProps("isAnonymous")}
                form={formik}
              />
              {formik.touched.is_anonymous && formik.errors.is_anonymous ? (
                <div className="text-red-500">{formik.errors.is_anonymous}</div>
              ) : null}
            </div>

            <div className="mb-6">
              <Switch
                title="Masquer le montant"
                description="Dans les commentaires, votre nom et le montant ne seront pas affichés"
                name="hidden_amount"
                field={formik.getFieldProps("hidden_amount")}
                form={formik}
              />
              {formik.touched.hidden_amount &&
              formik.errors.hidden_amount ? (
                <div className="text-red-500">
                  {formik.errors.hidden_amount}
                </div>
              ) : null}
            </div>

            <SubmitBtnWithLoader
                label="Participer à la cagnotte"
                status={initializePaymentStatus}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default CagnottePay;
