interface ButtonProps {
  children: React.ReactNode;
  classButton?: string;
  type?: "button" | "submit" | "reset";
  click?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  children,
  classButton,
  type,
  click,
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={`bg-corail focus:ring-4 focus:outline-none focus:ring-corail-500 hover:bg-corail-500 text-white py-2 px-4 rounded ${classButton}`}
      onClick={click}>
      {children}
    </button>
  );
};

export default Button;
