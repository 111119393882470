import {createSlice} from "@reduxjs/toolkit";
import {updateUserInfos} from "./thunk/updateUserinfos.thunk";
import {UserInfoState} from "../user-infos/userInfos.state";

const initialState: UserInfoState = {
  isAuthenticated: !!localStorage.getItem("authToken"),
  token: localStorage.getItem("authToken") ?? "",
  userInfos: null,
  status: "idle",
  error: null,
};

const updateUserInfosSlice = createSlice({
  name: "user-info",
  initialState: initialState,
  reducers: {
    resetStatus: state => {
      state.status = "idle";
    },
  },
  extraReducers: builder => {
    builder
      .addCase(updateUserInfos.pending, state => {
        state.status = "loading";
      })
      .addCase(updateUserInfos.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isAuthenticated = true;
        state.userInfos = action.payload;
      })
      .addCase(updateUserInfos.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

const {actions} = updateUserInfosSlice;
export const {resetStatus} = actions;
export default updateUserInfosSlice.reducer;
