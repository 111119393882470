import {createAsyncThunk} from "@reduxjs/toolkit";
import {GetDetailCagnotteCommand} from "../command/GetDetailCagnotteCommand";
import {axios} from "../../../../lib/axios";

export const fetchCagnotteDetails = createAsyncThunk(
  "cagnotte/fetchDetails",
  async (value: GetDetailCagnotteCommand) => {
    const response = await axios.get(
      `jackpots/${value.id}/99b93824-8625-4546-98eb-97eebc37b172` // Côte d'Ivoire
    );
    return response.data;
  }
);
