import {createAsyncThunk} from "@reduxjs/toolkit";
import {GetCagnotteMessageCommand} from "../command/GetCagnotteMessageCommand";
import {axios} from "../../../../lib/axios";

export const fetchAllMessages = createAsyncThunk(
  "get/cagnotteMessages",
  async ({cagnotteId, country}: GetCagnotteMessageCommand) => {
    const response = await axios.get(
        `cashs/${cagnotteId}/${country}/in`
    );

    return response.data;
  }
);
