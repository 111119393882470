import {createSlice} from "@reduxjs/toolkit";
import {MessagesState} from "../states/messages.state";
import {fetchAllMessages} from "../thunk/fetchAllMessages.thunk";

const initialState: MessagesState = {
  extras: [],
  status: "idle",
  error: null,
};

const messagesSlice = createSlice({
  name: "cagnotteMessages",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAllMessages.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchAllMessages.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.extras = action.payload;
      })
      .addCase(fetchAllMessages.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default messagesSlice.reducer;
